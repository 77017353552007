import * as React from "react"
import { useTheme } from "@mui/material/styles"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import moment from 'moment'

const Details = ({ data }) => {
  const theme = useTheme()
  const booking = data?.vehicleOrderDetails;

  const requestInformation = [
    {
      name: "Requester",
      value: booking?.lastModifierNickname ? booking?.lastModifierNickname : booking.creatorNickname
    },
    {
      name: "Date Submitted",
      value: booking?.modifiedAt ? moment(booking?.modifiedAt).format('Do MMMM YYYY') : moment(booking.createdAt).format('Do MMMM YYYY')
    },
    {
      name: "Status",
      value: booking?.status ?? "-"
    },
  ];

  const leaseInformation = [
    {
      name: "Request Type",
      value: booking?.requestType ?? "-"
    },
    {
      name: "Referring Rego",
      value: booking?.vehicleRegistration ?? "-"
    },
    {
      name: "Fleet",
      value: booking?.accountName ?? "-"
    },
    {
      name: "Type of Lease",
      value: booking?.leaseType ?? "-"
    },
    {
      name: "Term",
      value: booking?.term ?? "-"
    },
    {
      name: "Kms",
      value: booking?.odometer ?? "-"
    },
    {
      name: "Number of Tyres",
      value: booking?.numberOfTyres ?? "-"
    },
    {
      name: "Driver Name",
      value: booking?.driverName ?? "-"
    },
    {
      name: "Driver Email",
      value: booking?.driverEmail ?? "-"
    },
    {
      name: "Vehicle Delivery Location",
      value: booking?.location ?? "-"
    },
    {
      name: "Vehicle Delivery Location Notes",
      value: booking?.otherLocation ?? "-"
    },
    {
      name: "Notes",
      value: booking?.notes ?? "-"
    }
  ];

  const vehicleInformation = [
    {
      name: "Make",
      value: booking?.make ?? "-"
    },
    {
      name: "Model",
      value: booking?.model ?? "-"
    },
    {
      name: "Variant",
      value: booking?.variant ?? "-"
    },
    {
      name: "Colour",
      value: booking?.colour ?? "-"
    },
    {
      name: "Secondary Colour",
      value: booking?.secondaryColour ?? "-"
    },
    {
      name: "Drive Train",
      value: booking?.driveTrain ?? "-"
    },
    {
      name: "Notes",
      value: booking?.vehicleNotes ?? "-"
    },
  ];

  const additionalServices = [
    {
      name: "Fuel Card and Reporting",
      value: booking?.hasFuelCardAndReporting ?? false
    },
    {
      name: "Relief Vehicle (14 Days)*",
      value: booking?.hasReliefVehicle ?? false
    },
    {
      name: "RUC Management",
      value: booking?.hasRucManagement ?? false
    },
    {
      name: "Accident Management",
      value: booking?.hasAccidentManagement ?? false
    },
    {
      name: "Toll Management / Recharge",
      value: booking?.hasTollManagement ?? false
    },
    {
      name: "Infringement Management Recharge",
      value: booking?.hasInfringementManagement ?? false
    },
    {
      name: "FBT Reporting",
      value: booking?.hasFbtReporting ?? false
    },
    {
      name: "Telematics",
      value: booking?.hasTelematics ?? false
    },
    {
      name: "Fleet Portal",
      value: booking?.hasFleetPortal ?? false
    },
    {
      name: "Driver App",
      value: booking?.hasDriverApp ?? false
    },
    {
      name: "Roadside Assistance",
      value: booking?.hasRoadsideAssistance ?? false
    },
  ];

  const serviceNames = additionalServices
    ?.filter(info => info?.value)
    ?.map(info => info?.name)
    ?.join(', ');

  const accessoriesInformation = [
    {
      name: "Canopy Slide Windows",
      value: booking?.hasCanopySlideWindows ?? false,
      notes: booking?.canopySlideWindowsNotes ?? ""
    },
    {
      name: "Canopy Lift Up windows",
      value: booking?.hasCanopyLiftupWindows ?? false,
      notes: booking?.canopyLiftupWindowsNotes ?? ""
    },
    {
      name: "Central Locking for Canopy Selection",
      value: booking?.hasCentralLockingForCanopySelection ?? false,
      notes: booking?.centralLockingForCanopySelectionNotes ?? ""
    },
    {
      name: "Rubber Mats",
      value: booking?.hasRubberMats ?? false,
      notes: booking?.rubberMatsNotes ?? ""
    },
    {
      name: "Carpet Mats",
      value: booking?.hasCarpetMats ?? false,
      notes: booking?.carpetMatsNotes ?? ""
    },
    {
      name: "Deckliner",
      value: booking?.hasDeckliner ?? false,
      notes: booking?.decklinerNotes ?? ""
    },
    {
      name: "Hardlid",
      value: booking?.hasHardlid ?? false,
      notes: booking?.hardlidNotes ?? ""
    },
    {
      name: "Soft Tonneau Cover",
      value: booking?.hasSoftTonneauCover ?? false,
      notes: booking?.softTonneauCoverNotes ?? ""
    },
    {
      name: "Towbar & Wiring",
      value: booking?.hasTowbarWiring ?? false,
      notes: booking?.towbarWiringNotes ?? ""
    },
    {
      name: "Roofracks",
      value: booking?.hasRoofRacks ?? false,
      notes: booking?.roofRacksNotes ?? ""
    },
    {
      name: "Seat Covers",
      value: booking?.hasSeatCovers ?? false,
      notes: booking?.seatCoversNotes ?? ""
    },
    {
      name: "First Aid kit",
      value: booking?.hasFirstAidKit ?? false,
      notes: booking?.firstAidKitNotes ?? ""
    },
    {
      name: "Fire Extinguisher",
      value: booking?.hasFireExtinguisher ?? false,
      notes: booking?.fireExtinguisherNotes ?? ""
    },
    {
      name: "Cargo Barrier",
      value: booking?.hasCargoBarrier ?? false,
      notes: booking?.cargoBarrierNotes ?? ""
    },
    {
      name: "Tyre Upgrade",
      value: booking?.hasTyreUpgrade ?? false,
      notes: booking?.tyreUpgradeNotes ?? ""
    },
    {
      name: "Signwriting",
      value: booking?.hasSignWriting ?? false,
      notes: booking?.signWritingNotes ?? ""
    },
    {
      name: "Sports Bar",
      value: booking?.hasSportsBar ?? false,
      notes: booking?.sportsBarNotes ?? ""
    },
    {
      name: "Nudge Bar",
      value: booking?.hasNudgeBar ?? false,
      notes: booking?.nudgeBarNotes ?? ""
    }
  ];
  
  const accessoriesNames = accessoriesInformation
    ?.filter(info => info.value)
    ?.map(info => `${info.name}${info.notes ? ` (${info.notes})` : ''}`)
    ?.join(', ');

  return (
    <Stack>
      <Stack flexDirection="column" width="100%" spacing={1}>
        <Typography variant="body2" fontWeight={600}>Request Info</Typography>
        <Stack flexDirection="row" flexWrap="wrap" alignItems="flex-start" width="100%">
          {requestInformation?.map((info, index) => (
            <Stack
              flex={1}
              minWidth='calc(100%/4)'
              maxWidth='calc(100%/4)'
              key={`request-information-${index}`}
              pb={2}
            >
              <Typography variant="bodyLarge" color={theme.palette.accent.sky}>
                {info?.name}
              </Typography>
              <Typography
                variant="bodyLarge"
                color={info?.value === "Submitted" ? '#43A047' : ''}
              >
                {info?.value}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
        <Stack flexDirection="column" width="100%" spacing={1}>
          <Typography variant="body2" fontWeight={600}>Lease Information</Typography>
          <Stack flexDirection="row" flexWrap="wrap" alignItems="flex-start" width="100%">
            {leaseInformation?.map((info, index) => (
              <Stack
                flex={1}
                minWidth='calc(100%/4)'
                maxWidth='calc(100%/4)'
                key={`lease-information-${index}`}
                pb={2}
              >
                <Typography variant="bodyLarge" color={theme.palette.accent.sky}>
                  {info?.name}
                </Typography>
                <Typography variant="bodyLarge">
                  {info?.value}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Stack flexDirection="column" width="100%" spacing={1}>
          <Typography variant="body2" fontWeight={600}>Vehicle Information</Typography>
          <Stack flexDirection="row" flexWrap="wrap" alignItems="flex-start" width="100%">
            {vehicleInformation?.map((info, index) => (
              <Stack
                flex={1}
                minWidth='calc(100%/4)'
                maxWidth='calc(100%/4)'
                key={`vehicle-information-${index}`}
                pb={2}
              >
                <Typography variant="bodyLarge" color={theme.palette.accent.sky}>
                  {info?.name}
                </Typography>
                <Typography variant="bodyLarge">
                  {info?.value}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>

        <Stack flexDirection="column" width="100%" spacing={1}>
          <Typography variant="body2" fontWeight={600}>Additional Services</Typography>
          <Stack flexDirection="row" flexWrap="wrap" alignItems="flex-start" width="100%" pb={2}>
            {serviceNames && (
              <Typography variant="bodyLarge">
                {serviceNames}
              </Typography>
            )}
            { booking?.additionalServiceNotes &&
              <Stack flexDirection="row" flexWrap="wrap" alignItems="flex-start" width="100%">
                <Typography variant="bodyLarge">
                  {booking?.additionalServiceNotes}
                </Typography>
              </Stack>
            }
            {!serviceNames && (
              <Typography variant="bodyLarge">
                {'-'}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Stack flexDirection="column" width="100%" spacing={1}>
          <Typography variant="body2" fontWeight={600}>Accessories</Typography>
          <Stack flexDirection="row" flexWrap="wrap" alignItems="flex-start" width="100%">
            {accessoriesNames && (
              <Typography variant="bodyLarge">
                {accessoriesNames}
              </Typography>
            )}
            { booking?.otherAccessories &&
              <Stack flexDirection="row" flexWrap="wrap" alignItems="flex-start" width="100%">
                <Typography variant="bodyLarge">
                  {booking?.otherAccessories}
                </Typography>
              </Stack>
            }
            {!accessoriesNames && (
              <Typography variant="bodyLarge">
                {'-'}
              </Typography>
            )}
          </Stack>
        </Stack>

      {/* </Stack> */}
    </Stack>
  )
}

export default Details