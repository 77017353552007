import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { getRole } from 'listable'
import { hasRoles } from 'acl'
import { types } from '../../module'
import { Alert, ScrollToButton } from 'frame/components'
import { isBornUser } from 'utils'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import MuiTextfield from 'frame/components/mui-textfield'
import MuiSelectfield from 'frame/components/mui-form-select'

import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { schema } from '../../../admin-invitations/components/new/schema'
import StyledModalV2, { StyledModalV2Actions } from 'frame/components/styled-modal-v2'

const Form = ({
  error,
  data = {},
  disabled,
  fleets,
  roles,
  userRoles,
  onCancel,
  onDelete,
  onBlock,
  onSubmit,
  onChange,
  currentUser
}) => {
  const theme = useTheme()
  const saveButtonRef = useRef()
  const form = useRef()

  const methods = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "all",
  })

  const [fleetIds, setFleetIds] = useState(data?.fleets || []) // for fleets checkbox state

  useEffect(() => { // fixes late fleet initializing issues
    if(data?.fleets) {
      setFleetIds(data?.fleets)
    }// eslint-disable-next-line
  }, [data?.fleets])

  
  //for delete/user deactivation confirmation
  const [openDeactivationModal, setOpenDeactivationModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [showRequiredFleetsError, setShowRequiredFleetsError] = useState(false)

  // fleet is required except for SuperAdmin Role
  const isValidFleetSelction = (data.role && data.role.name === 'SuperAdmin') || fleetIds.length > 0

  const isSelected = fleetId => JSON.stringify(data.fleets).includes(fleetId)  

  const onInputChange = ({ target: { id, name, value } }) =>
    onChange({
      ...data,
      [id || name]: value,
    })

  const onSelectRoleChange = value => {
    let role = roles.filter(node => node.id === value)[0]
    onChange({ ...data, role })
  }

  const onSelect = (fleetId) => () => {  
    if (isSelected(fleetId)) {
      const fleetRemoved = fleetIds && fleetIds.filter(node => node.id !== fleetId)
      setFleetIds(fleetRemoved)
      onChange({
        ...data,
        fleets: fleetRemoved
      })
      return
    }

    let fleet = fleets && fleets.filter(node => node.value === fleetId)[0]
    let fleetToAdd = { id: fleet.value, name: fleet.label }
    setFleetIds(fleetIds.concat(fleetToAdd))
    onChange({
      ...data,
      fleets: fleetIds.concat(fleetToAdd)
    })
  }

  const onSaveValidation = () => {
    if (form.current) {
      form.current.submit()
      !isValidFleetSelction && setShowRequiredFleetsError(true)
    }
  }

  const onSubmitForm = () => isValidFleetSelction &&   
    onSubmit({
      ...data,
      nickname: data.nickname.trim(),
      fleets: fleetIds,
    })

  const roleOptions = roles && roles.map(node => ({
    label: getRole(node.name),
    value: node.id,
  }))
  
  const onDeactivateUser = () => {
    onBlock(`?userId=${data.id}&block=${!data.blocked}`)
    onCancel()
  }

  const onDeleteUser = () => {
    onDelete(data.id)
    onCancel()
  }

  useEffect(() => {
    fleetIds.length > 0 && setShowRequiredFleetsError(false)
  }, [fleetIds])

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidEmail', (value) => {

      if(value === '') return true
      
      let domain = value.split(/@/)[1]
      let ext = domain && domain.split(/\./)[1]
      let isValidDomain = ext && ext.length > 1 // to avoid single character email extension 
      let isEmailFormat = /^[^\s@]{2,}@[^\s@]{2,}\.[^\s@]{2,}$/.test(value)

      return isEmailFormat && isValidDomain
    })
    return () => {
      ValidatorForm.removeValidationRule('isValidEmail')
    }
  }, [data.email])

  return (
    <ValidatorForm autoComplete="off" noValidate ref={form} onSubmit={onSubmitForm}>
      <Card
        sx={{
          boxShadow: 'none',
          borderRadius: '5px',
        }}
      >
        <CardContent
          sx={{
            p: 3,
          }}
        >
          {error && (
            <Alert gap={3}>
              Something went wrong whilst trying to add this user.
            </Alert>
          )}

          <Stack gap={3}>
            {/* Details */}
            <Stack
              flexDirection="column"
              gap={3}
              border='1px solid'
              borderColor={theme.palette.accent.sky}
              padding={2}
              borderRadius={1}
            >
              <Stack flex={1}>
                <Typography variant="h5" fontWeight={600}>
                  Details
                </Typography>
              </Stack>

              <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
                <Stack gap={1} flex={1}>
                  <Controller
                    name="nickname"
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <MuiTextfield
                        {...field}
                        id="nickname"
                        error={Boolean(methods.formState.errors?.nickname?.message)}
                        label="Name"
                        disabled={disabled}
                        value={data.nickname || ""}
                        onChange={onInputChange}
                      />
                    )}
                  />
                  <Typography color="error" fontSize={12}>
                    {methods.formState.errors?.nickname?.message}
                  </Typography>
                </Stack>
                <Stack gap={1} flex={1}>
                  <Controller
                    disabled={true}
                    name="email"
                    control={methods.control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <MuiTextfield
                        {...field}
                        id="email"
                        error={Boolean(methods.formState.errors?.email?.message)}
                        label="Email"
                        disabled={true}
                        value={data.email || ""}
                        onChange={onInputChange}
                      />
                    )}
                  />
                  <Typography color="error" fontSize={12}>
                    {methods.formState.errors?.email?.message}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" gap={2} flex={2}>
                  <Stack gap={1} flex={1}>
                    <Controller
                      name="role"
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <MuiSelectfield
                          {...field}
                          id="role"
                          error={Boolean(methods.formState.errors?.email?.message)}
                          label="Role"
                          options={roleOptions || []}
                          disabled={disabled}
                          value={(data.role && data.role.id) || ''}
                          onChange={(event) => onSelectRoleChange(event?.target?.value)}
                        />
                      )}
                    />
                    <Typography color="error" fontSize={12}>
                      {methods.formState.errors?.role?.message}
                    </Typography>
                  </Stack>
                  <Stack gap={1} flex={1}>
                    <MuiTextfield
                      id="status"
                      disabled={true}
                      fullWidth
                      label="Status"
                      value={data.blocked ? 'Blocked' : 'Active'}
                      variant="outlined" 
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            {/* Fleets */}
            <Stack
              flexDirection="column"
              gap={3}
              border='1px solid'
              borderColor={theme.palette.accent.sky}
              padding={2}
              borderRadius={1}
            >
              <Stack flex={1}>
                <Typography variant="h5" fontWeight={600}>
                  Fleets
                </Typography>
                <Typography variant="bodyMedium">
                  Please select the fleets to assign to this user.
                </Typography>
              </Stack>

              <Stack flexDirection="row" gap={2}>
                <Grid container py={2}>
                  {fleets.map((node, index) => (
                    <Grid key={index} item sm={6} xs={12}>
                      <FormControlLabel
                        id="checkbox-form-control"
                        control={
                          <Checkbox
                            checked={isSelected(node.value)}
                            onChange={onSelect(node.value)}
                            value={node.value}
                            color="default"
                            sx={{
                              color: theme.palette.accent.sky,
                              '&.Mui-checked': {
                                color: theme.palette.accent.sky,
                              },
                            }}
                          />
                        }
                        label={node.label}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            </Stack>

          </Stack>
          <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />
          <Stack
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent='space-between'
            gap={1}
          >
            <Box width={{ xs: '100%', sm: '205px' }}>
              <Button
                fullWidth
                color='error'
                disabled={disabled}
                variant="outlined"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </Box>
            <Stack flexDirection="row" gap={2}>
              <Box width={{ xs: '100%', sm: '205px' }}>
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="outlined"
                  disabled={disabled}
                  onClick={onSaveValidation}
                >
                  Save Changes
                </Button>
              </Box>
              {hasRoles(['superadmin', 'fleetcontroller'], userRoles) && (
                <Box width={{ xs: '100%', sm: '205px' }}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    disabled={disabled}
                    onClick={() => setOpenDeactivationModal(true)}
                  >
                  {data.blocked ? 'Activate User' : 'Deactivate User'}
                  </Button>
                </Box>
              )}
              {hasRoles(['superadmin'], userRoles) && isBornUser(currentUser.email) 
                && !isBornUser(data.email) // prevent deleting main born accounts 
                && (
                  <Box width={{ xs: '100%', sm: '205px' }}>
                    <Button
                      fullWidth
                      color="primary"
                      disabled={disabled}
                      variant="contained"
                      onClick={() => setOpenDeleteModal(true)}
                    >
                      Delete User
                    </Button>
                  </Box>
              )}
            </Stack>
          </Stack>
        </CardContent>
        
        <ScrollToButton scrollToRef={saveButtonRef} />
      </Card>

      {/* for user deactivation */}
      <StyledModalV2
        open={openDeactivationModal}
        onClose={() => setOpenDeactivationModal(false)}
        content={
          <Typography variant="h2" textAlign="center">
            {`Confirm User ${data.blocked ? 'Activation' : 'Deactivation'}`}
            <Typography variant="h4" textAlign="center">
              {`Please confirm to ${data.blocked ? 'activate' : 'deactivate'} user ${data.nickname}.`}
            </Typography>
          </Typography>
        }
        actions={
          <StyledModalV2Actions
            onCancel={() => setOpenDeactivationModal(false)}
            onConfirm={onDeactivateUser}
          />
        }
      />

      {/* for delete */}
      <StyledModalV2
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        content={
          <Typography variant="h2" textAlign="center">
            {`Confirm Delete User`}
            <Typography variant="h4" textAlign="center">
              {`Please confirm to delete user ${data.nickname}.`}
            </Typography>
          </Typography>
        }
        actions={
          <StyledModalV2Actions
            onCancel={() => setOpenDeleteModal(false)}
            onConfirm={onDeleteUser}
          />
        }
      />
    </ValidatorForm>
  )
}

Form.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  data: PropTypes.oneOfType([
    PropTypes.object,
    types.ModelType,
  ]).isRequired,
  fleets: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onBlock: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentUser: PropTypes.object.isRequired,
}

export default Form
