import React, { useContext, useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import axios from 'axios'
import { getAppConfig } from 'app-config'
import { getAuthClient } from 'auth'
import { zodResolver } from '@hookform/resolvers/zod'
import { schema } from './form-schema'
import PropTypes from 'prop-types'
import { useNavigate, useRouter, lookupRequest } from 'utils'
import { AsyncAutocomplete } from 'frame/components'
import KeyValue from 'frame/components/mui-textfield'
import LeaseTypeDetails from './lease-type-details'
import { constants } from '../../../module'
import { useSelectedFleets, useSelectedFleetIds } from "features/settings"
import StyledModalV2, { StyledModalV2Actions } from 'frame/components/styled-modal-v2'

import { FormContext } from '../form'
import { NewVehicleSummaryContext } from '../new'
import { ItemVehicleSummaryContext } from '../../item/item'

import Autocomplete from '@mui/material/Autocomplete'
import Button from "@mui/material/Button"
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import MuiFormSelect from "frame/components/mui-form-select"
import MuiTextfield from 'frame/components/mui-textfield'
import Dialog  from '@mui/material/Dialog'
import DialogContent  from '@mui/material/DialogContent'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import IconButton from '@mui/material/IconButton'
import { useTheme } from "@mui/material/styles"
import Spinner from 'react-md-spinner'
import StaticAutocomplete from 'frame/components/staticautocomplete'
import VehicleLocations from './vehicle-locations'

const LeaseInformation = ({
  data,
  onSubmit,
  notEditable,
  error,
  errorMessage,
}) => {
  const newContext = useContext(NewVehicleSummaryContext);
  const itemContext = useContext(ItemVehicleSummaryContext);
  const formContext = useContext(FormContext);
  const {
    values,
    onInputChange,
    onSelectChange,
    setValues,
  } = formContext

  const theme = useTheme()
  const navigate = useNavigate()
  const { match } = useRouter()
  const config = getAppConfig();
  const authClient = getAuthClient();
  
  const [loading, setLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmSaveDialogOpen, setConfirmSaveDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const userSelectedFleets = useSelectedFleets()
  const fleetIds = useSelectedFleetIds()
  const requestTypeNew = 'New/Extra vehicle';
  const requestTypeReplacement = 'Replacement';

  const filterOptions = (options, { inputValue }) => {
    const filtered = options.filter(option => option.text.toLowerCase().includes(inputValue.toLowerCase()));
    if (!filtered.some(option => option.value === 'Other')) {
      filtered.unshift({ text: 'Other', value: 'Other' });
    }
    return filtered;
  };
  
  const methods = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "all",
    defaultValues: {
      accountName: values?.accountName ?? "",
      leaseType: values?.leaseType ?? "",
      term: values?.term ?? "",
      odometer: values?.odometer ?? "",
      otherLocation: values?.otherLocation ?? "",
    }
  })

  const onClose = navigate(constants.PATH_ALL)

  const fleetOptions = userSelectedFleets.map(item => ({
    label: item.label,
    value: item.value,
  }))
  
  const onFleetChange = (value) => {
    const fleet = userSelectedFleets.filter(f => f.value === value)[0]
    const thirdPartyNumber = fleet ? fleet.value : null
    setValues({
      ...values,
      thirdPartyNumber: thirdPartyNumber,
      accountName: value ?? "",
    })
    methods.setValue("accountName", value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const onLeaseTypeChange = (value) => {
    setValues((prevValues) => ({
      ...prevValues,
      leaseType: value,
      numberOfTyres: value === 'Fully Maintained Operating Lease' && prevValues.odometer > 30000 ? '4' : '0',
    }));
    methods.setValue("leaseType", value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const onKmsChange = (value) => {
    setValues((prevValues) => ({
      ...prevValues,
      odometer: value,
      numberOfTyres: prevValues.leaseType === 'Fully Maintained Operating Lease' ? (value > 30000 ? '4' : '0') : '0',
    }));
    methods.setValue("odometer", value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const onLocationChange = (selectedOption) => {
    setValues({
      ...values,
      location: selectedOption,
    })
    methods.setValue("location", selectedOption?.value, {
      shouldValidate: true,
      shouldDirty: true,
    })
    methods.setValue("otherLocation", values?.otherLocation, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }
  
  const handleInputChange = ({ target: { id, name, value } }) => {
    setValues({ ...values, [id || name]: value })
    methods.setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
    })
  }

  const buildApiEndpoint = ( path, fleetIds) => {
    const params = new URLSearchParams();
    if (fleetIds && fleetIds.length > 0) {
      params.append('FleetId', fleetIds.join(','));
    }
    return `${path}?${params.toString()}`;
  };

  const apiEndpoint = buildApiEndpoint('/api/lookup/vehicleregistration', fleetIds);

  const fetchOrderDetails = async (vehicleRegistration) => {
    setLoading(true);
    try {
      const accessToken = await authClient.getTokenSilently();
      const headers = { Authorization: `Bearer ${accessToken}` };
      const response = await axios.get(`${config.app.api}vehicle/GetReferencedVehicleData/${vehicleRegistration.value}`, {
        headers: headers,
      });
      const data = response.data.data;
      setValues((prevValues) => ({
        ...prevValues,
        lproceed: values.requestType === requestTypeNew ? true : false,
        requestType: values.requestType,
        accountName1: data.thirdPartyNumber,
        leaseType1: data.agreementType,
        term1: data.term?.toString() ?? 0,
        odometer1: data.contractKm ? parseInt(data.contractKm, 10).toString() : '0',
        numberOfTyres1: data.numberOfTyres?.toString() ?? 0,
        year: data.year ?? "",
        make1: data.make ?? "",
        model1: data.model ?? "",
        colour1: data.colour ?? "",
        driveTrain1: "Petrol", //ToDo
        variant1: data.variant ?? "",
        vehicleRegistration: vehicleRegistration,
      }));
    } catch (error) {
      console.error('Error fetching order details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectedOptionChange = (selectedOption) => {
    fetchOrderDetails(selectedOption);
  };

  const handleCopyAllFields = () => {
    methods.setValue("accountName", values.accountName1, {
      shouldValidate: true,
      shouldDirty: true,
    });
    methods.setValue("leaseType", values.leaseType1, {
      shouldValidate: true,
      shouldDirty: true,
    });
    methods.setValue("term", values.term1, {
      shouldValidate: true,
      shouldDirty: true,
    });
    onKmsChange((values.odometer1).toString());

    const fleet = userSelectedFleets.filter(f => f.value === values.accountName1)[0]
    const thirdPartyNumber = fleet ? fleet.value : null
    setValues((prevValues) => ({
      ...prevValues,
      accountName: values.accountName1,
      thirdPartyNumber: thirdPartyNumber,
      leaseType : values.leaseType1,
      term: values.term1,
      odometer: values.odometer1,
      numberOfTyres: values.numberOfTyres1,
    }));
  };

  const handleProceed = () => {
    if(values.requestType === requestTypeNew && !values.lproceed){
      setValues((prevValues) => ({
        ...prevValues,
        lproceed : true,
      }));
    } else {
      navigate(`${match.url}${constants.PATH_VEHICLE_INFORMATION}`)(0);
      if(data?.vehicleOrderDetails?.status && data?.vehicleOrderDetails?.status === 'Draft'){
        itemContext.setActiveTab("vehicle_information");
      } else{
        newContext.setActiveTab("vehicle_information");
        newContext.setDisabledTabs({
          vehicleInformation: false
        });
        newContext.setValues(values)
      }
    }
  }

  const onSubmitForm = () => {
    setValues({
      ...values,
      requestType: values.requestType,
      vehicleRegistration: (values.vehicleRegistration?.value) ?? null,
      location: (values.location?.value) ?? null,
      leaseType: values.leaseType,
      term: values.term,
      odometer: values.odometer === "" ? '0' : values.odometer,
      numberOfTyres: values.numberOfTyres === "" ? 0 : values.numberOfTyres,
    });
    if(typeof values.vehicleRegistration === 'object' && values.vehicleRegistration !== null ) {
      values.vehicleRegistration = values.vehicleRegistration?.value;
    }
    if(typeof values.location === 'object' && values.location !== null ) {
      values.location = values.location?.value;
    }
    if(values.thirdPartyNumber && values.accountName){
      const fleet = userSelectedFleets.filter(f => f.value === values.thirdPartyNumber)[0]
      const accountName = fleet ? fleet.label : null
      values.accountName = accountName;
    }
    if (values.requestType 
      && values.leaseType
      && values.accountName
      && values.term
      && values.odometer
      && values.location
      && values.location?.value != ''
      && ((values.location === 'Other' && values.otherLocation) || values.location !== 'Other')
    ) {
      onSubmit(values)
      setConfirmDialogOpen(true);
    }
  }

  useEffect(() => {
    async function fetchAndsetThirdPartyNumber() {
      if(userSelectedFleets.length === 1) {
        const data = await lookupRequest(fleetIds, "/lookup/fleetId", userSelectedFleets[0].label)
        data && setValues({
          ...values,
          thirdPartyNumber: data.value || "-",
          accountName1: data.value || "-",
          accountName: data.value || "-",
        })
      }
    }
    fetchAndsetThirdPartyNumber()
  }, [])

  useEffect(() => {
    const locationDet = {
      text: values?.location?.value || '',
      value: values?.location?.value || '',
    };
    setValues({
      ...values,
      location: locationDet
    })
    if(values?.location?.value && values?.location?.value != ''){
      methods.setValue("location", values?.location?.value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    const vehicleOrderDetails = data?.vehicleOrderDetails;
    if(vehicleOrderDetails && vehicleOrderDetails.status === 'Draft' ){
      const initialVehicleRegistration = {
        agreementNo: null,
        text: vehicleOrderDetails.vehicleRegistration || null,
        value: vehicleOrderDetails.vehicleRegistration || null,
      };
      const locationDetails = {
        text: vehicleOrderDetails.location || '',
        value: vehicleOrderDetails.location || '',
      };
      methods.setValue("accountName", values.accountName, {
        shouldValidate: true,
        shouldDirty: true,
      });
      methods.setValue("leaseType", values.leaseType, {
        shouldValidate: true,
        shouldDirty: true,
      });
      methods.setValue("term", values.term, {
        shouldValidate: true,
        shouldDirty: true,
      });
      methods.setValue("odometer", vehicleOrderDetails.odometer.toString(), {
        shouldValidate: true,
        shouldDirty: true,
      });
      methods.setValue("location", values.location?.value, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValues((values) => ({
        ...values,
        // Lease Information
        lproceed: vehicleOrderDetails.requestType === requestTypeNew ? true: false,
        
        requestType: vehicleOrderDetails.requestType && !values.requestType ? vehicleOrderDetails.requestType : values.requestType,
        vehicleRegistration: initialVehicleRegistration,
        term: vehicleOrderDetails.term && !values.term ? vehicleOrderDetails.term : values.term,
        term1: vehicleOrderDetails?.referencedVehicleData?.term,
        odometer: vehicleOrderDetails.odometer && !values.odometer ? vehicleOrderDetails.odometer : values.odometer,
        odometer1: vehicleOrderDetails?.referencedVehicleData?.contractKm,
        numberOfTyres: vehicleOrderDetails.numberOfTyres && !values.numberOfTyres ? vehicleOrderDetails.numberOfTyres : values.numberOfTyres,
        numberOfTyres1: vehicleOrderDetails?.referencedVehicleData?.numberOfTyres,
        driverName: vehicleOrderDetails.driverName && !values.driverName ? vehicleOrderDetails.driverName : values.driverName,
        driverEmail: vehicleOrderDetails.driverEmail && !values.driverEmail ? vehicleOrderDetails.driverEmail : values.driverEmail,
        location: locationDet?.value && locationDet != locationDetails ? locationDet : locationDetails,
        otherLocation: vehicleOrderDetails.otherLocation && !values.otherLocation ? vehicleOrderDetails.otherLocation : values.otherLocation,
        notes: vehicleOrderDetails.notes && !values.notes ? vehicleOrderDetails.notes : values.notes,
        leaseType: vehicleOrderDetails.leaseType && !values.leaseType ? vehicleOrderDetails.leaseType : values.leaseType,
        leaseType1: vehicleOrderDetails?.referencedVehicleData?.agreementType,
        thirdPartyNumber: vehicleOrderDetails.thirdPartyNumber && !values.thirdPartyNumber ? vehicleOrderDetails.thirdPartyNumber : values.thirdPartyNumber,
        accountName: vehicleOrderDetails.thirdPartyNumber && !values.accountName ? vehicleOrderDetails.thirdPartyNumber : values.accountName,
        accountName1: vehicleOrderDetails?.referencedVehicleData?.thirdPartyNumber,
        // Vehicle Information
        year: vehicleOrderDetails?.referencedVehicleData?.year,
        make: vehicleOrderDetails.make && !values.make ? vehicleOrderDetails.make : values.make,
        make1: vehicleOrderDetails?.referencedVehicleData?.make,
        model: vehicleOrderDetails.model && !values.model ? vehicleOrderDetails.model : values.model,
        model1: vehicleOrderDetails?.referencedVehicleData?.model,
        variant: vehicleOrderDetails.variant && !values.variant ? vehicleOrderDetails.variant : values.variant,
        variant1: vehicleOrderDetails?.referencedVehicleData?.variant,
        colour: vehicleOrderDetails.colour && !values.colour ? vehicleOrderDetails.colour : values.colour,
        colour1: vehicleOrderDetails?.referencedVehicleData?.colour,
        secondaryColour: vehicleOrderDetails.secondaryColour && !values.secondaryColour ? vehicleOrderDetails.secondaryColour : values.secondaryColour,
        driveTrain: vehicleOrderDetails.driveTrain && !values.driveTrain ? vehicleOrderDetails.driveTrain : values.driveTrain,
        driveTrain1: "Petrol",
        vehicleNotes: vehicleOrderDetails.vehicleNotes && !values.vehicleNotes ? vehicleOrderDetails.vehicleNotes : values.vehicleNotes,
        id: vehicleOrderDetails.id,
        // Additional Services
        hasFuelCardAndReporting: vehicleOrderDetails.hasFuelCardAndReporting && !values.hasFuelCardAndReporting ? vehicleOrderDetails.hasFuelCardAndReporting : values.hasFuelCardAndReporting,
        hasReliefVehicle: vehicleOrderDetails.hasReliefVehicle && !values.hasReliefVehicle ? vehicleOrderDetails.hasReliefVehicle : values.hasReliefVehicle,
        hasRucManagement: vehicleOrderDetails.hasRucManagement && !values.hasRucManagement ? vehicleOrderDetails.hasRucManagement : values.hasRucManagement,
        hasAccidentManagement: vehicleOrderDetails.hasAccidentManagement && !values.hasAccidentManagement ? vehicleOrderDetails.hasAccidentManagement : values.hasAccidentManagement,
        hasTollManagement: vehicleOrderDetails.hasTollManagement && !values.hasTollManagement ? vehicleOrderDetails.hasTollManagement : values.hasTollManagement,
        hasInfringementManagement: vehicleOrderDetails.hasInfringementManagement && !values.hasInfringementManagement ? vehicleOrderDetails.hasInfringementManagement : values.hasInfringementManagement,
        hasFbtReporting: vehicleOrderDetails.hasFbtReporting && !values.hasFbtReporting ? vehicleOrderDetails.hasFbtReporting : values.hasFbtReporting,
        hasTelematics: vehicleOrderDetails.hasTelematics && !values.hasTelematics ? vehicleOrderDetails.hasTelematics : values.hasTelematics,
        hasFleetPortal: vehicleOrderDetails.hasFleetPortal && !values.hasFleetPortal ? vehicleOrderDetails.hasFleetPortal : values.hasFleetPortal,
        hasDriverApp: vehicleOrderDetails.hasDriverApp && !values.hasDriverApp ? vehicleOrderDetails.hasDriverApp : values.hasDriverApp,
        hasRoadsideAssistance: vehicleOrderDetails.hasRoadsideAssistance && !values.hasRoadsideAssistance ? vehicleOrderDetails.hasRoadsideAssistance : values.hasRoadsideAssistance,
        additionalServiceNotes: vehicleOrderDetails.additionalServiceNotes && !values.additionalServiceNotes ? vehicleOrderDetails.additionalServiceNotes : values.additionalServiceNotes,
        // Accessories
        hasCanopySlideWindows: vehicleOrderDetails.hasCanopySlideWindows && !values.hasCanopySlideWindows ? vehicleOrderDetails.hasCanopySlideWindows : values.hasCanopySlideWindows,
        canopySlideWindowsNotes: vehicleOrderDetails.canopySlideWindowsNotes && !values.canopySlideWindowsNotes ? vehicleOrderDetails.canopySlideWindowsNotes : values.canopySlideWindowsNotes,
        hasCanopyLiftupWindows: vehicleOrderDetails.hasCanopyLiftupWindows && !values.hasCanopyLiftupWindows ? vehicleOrderDetails.hasCanopyLiftupWindows : values.hasCanopyLiftupWindows,
        canopyLiftupWindowsNotes: vehicleOrderDetails.canopyLiftupWindowsNotes && !values.canopyLiftupWindowsNotes ? vehicleOrderDetails.canopyLiftupWindowsNotes : values.canopyLiftupWindowsNotes,
        hasCentralLockingForCanopySelection: vehicleOrderDetails.hasCentralLockingForCanopySelection && !values.hasCentralLockingForCanopySelection ? vehicleOrderDetails.hasCentralLockingForCanopySelection : values.hasCentralLockingForCanopySelection,
        centralLockingForCanopySelectionNotes: vehicleOrderDetails.centralLockingForCanopySelectionNotes && !values.centralLockingForCanopySelectionNotes ? vehicleOrderDetails.centralLockingForCanopySelectionNotes : values.centralLockingForCanopySelectionNotes,
        hasRubberMats: vehicleOrderDetails.hasRubberMats && !values.hasRubberMats ? vehicleOrderDetails.hasRubberMats : values.hasRubberMats,
        rubberMatsNotes: vehicleOrderDetails.rubberMatsNotes && !values.rubberMatsNotes ? vehicleOrderDetails.rubberMatsNotes : values.rubberMatsNotes,
        hasCarpetMats: vehicleOrderDetails.hasCarpetMats && !values.hasCarpetMats ? vehicleOrderDetails.hasCarpetMats : values.hasCarpetMats,
        carpetMatsNotes: vehicleOrderDetails.carpetMatsNotes && !values.carpetMatsNotes ? vehicleOrderDetails.carpetMatsNotes : values.carpetMatsNotes,
        hasDeckliner: vehicleOrderDetails.hasDeckliner && !values.hasDeckliner ? vehicleOrderDetails.hasDeckliner : values.hasDeckliner,
        decklinerNotes: vehicleOrderDetails.decklinerNotes && !values.decklinerNotes ? vehicleOrderDetails.decklinerNotes : values.decklinerNotes,
        hasHardlid: vehicleOrderDetails.hasHardlid && !values.hasHardlid ? vehicleOrderDetails.hasHardlid : values.hasHardlid,
        hardlidNotes: vehicleOrderDetails.hardlidNotes && !values.hardlidNotes ? vehicleOrderDetails.hardlidNotes : values.hardlidNotes,
        hasSoftTonneauCover: vehicleOrderDetails.hasSoftTonneauCover && !values.hasSoftTonneauCover ? vehicleOrderDetails.hasSoftTonneauCover : values.hasSoftTonneauCover,
        softTonneauCoverNotes: vehicleOrderDetails.softTonneauCoverNotes && !values.softTonneauCoverNotes ? vehicleOrderDetails.softTonneauCoverNotes : values.softTonneauCoverNotes,
        hasTowbarWiring: vehicleOrderDetails.hasTowbarWiring && !values.hasTowbarWiring ? vehicleOrderDetails.hasTowbarWiring : values.hasTowbarWiring,
        towbarWiringNotes: vehicleOrderDetails.towbarWiringNotes && !values.towbarWiringNotes ? vehicleOrderDetails.towbarWiringNotes : values.towbarWiringNotes,
        hasRoofRacks: vehicleOrderDetails.hasRoofRacks && !values.hasRoofRacks ? vehicleOrderDetails.hasRoofRacks : values.hasRoofRacks,
        roofRacksNotes: vehicleOrderDetails.roofRacksNotes && !values.roofRacksNotes ? vehicleOrderDetails.roofRacksNotes : values.roofRacksNotes,
        hasSeatCovers: vehicleOrderDetails.hasSeatCovers && !values.hasSeatCovers ? vehicleOrderDetails.hasSeatCovers : values.hasSeatCovers,
        seatCoversNotes: vehicleOrderDetails.seatCoversNotes && !values.seatCoversNotes ? vehicleOrderDetails.seatCoversNotes : values.seatCoversNotes,
        hasFirstAidKit: vehicleOrderDetails.hasFirstAidKit && !values.hasFirstAidKit ? vehicleOrderDetails.hasFirstAidKit : values.hasFirstAidKit,
        firstAidKitNotes: vehicleOrderDetails.firstAidKitNotes && !values.firstAidKitNotes ? vehicleOrderDetails.firstAidKitNotes : values.firstAidKitNotes,
        hasFireExtinguisher: vehicleOrderDetails.hasFireExtinguisher && !values.hasFireExtinguisher ? vehicleOrderDetails.hasFireExtinguisher : values.hasFireExtinguisher,
        fireExtinguisherNotes: vehicleOrderDetails.fireExtinguisherNotes && !values.fireExtinguisherNotes ? vehicleOrderDetails.fireExtinguisherNotes : values.fireExtinguisherNotes,
        hasCargoBarrier: vehicleOrderDetails.hasCargoBarrier && !values.hasCargoBarrier ? vehicleOrderDetails.hasCargoBarrier : values.hasCargoBarrier,
        cargoBarrierNotes: vehicleOrderDetails.cargoBarrierNotes && !values.cargoBarrierNotes ? vehicleOrderDetails.cargoBarrierNotes : values.cargoBarrierNotes,
        hasTyreUpgrade: vehicleOrderDetails.hasTyreUpgrade && !values.hasTyreUpgrade ? vehicleOrderDetails.hasTyreUpgrade : values.hasTyreUpgrade,
        tyreUpgradeNotes: vehicleOrderDetails.tyreUpgradeNotes && !values.tyreUpgradeNotes ? vehicleOrderDetails.tyreUpgradeNotes : values.tyreUpgradeNotes,
        hasSignWriting: vehicleOrderDetails.hasSignWriting && !values.hasSignWriting ? vehicleOrderDetails.hasSignWriting : values.hasSignWriting,
        signWritingNotes: vehicleOrderDetails.signWritingNotes && !values.signWritingNotes ? vehicleOrderDetails.signWritingNotes : values.signWritingNotes,
        hasSportsBar: vehicleOrderDetails.hasSportsBar && !values.hasSportsBar ? vehicleOrderDetails.hasSportsBar : values.hasSportsBar,
        sportsBarNotes: vehicleOrderDetails.sportsBarNotes && !values.sportsBarNotes ? vehicleOrderDetails.sportsBarNotes : values.sportsBarNotes,
        hasNudgeBar: vehicleOrderDetails.hasNudgeBar && !values.hasNudgeBar ? vehicleOrderDetails.hasNudgeBar : values.hasNudgeBar,
        nudgeBarNotes: vehicleOrderDetails.nudgeBarNotes && !values.nudgeBarNotes ? vehicleOrderDetails.nudgeBarNotes : values.nudgeBarNotes,
        otherAccessories: vehicleOrderDetails.otherAccessories && !values.otherAccessories ? vehicleOrderDetails.otherAccessories : values.otherAccessories,
      }));
    }
  }, [data]);

  return (
    <form
      id="request-lease-information-form"
      onSubmit={methods.handleSubmit(onSubmitForm)}
    >
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
        <Stack flex={1}>
          <Controller
            name="requestType"
            control={methods.control}
            rules={{ required: false }}
            render={({ field }) => (
              <MuiFormSelect
                {...field}
                id="requestType"
                disabled={notEditable}
                label="Request Type*"
                labelId="requestType-label"
                options={[requestTypeNew, requestTypeReplacement]}
                value={values.requestType || ''}
                onChange={onInputChange}
              />
            )}
          />
        </Stack>
        <Stack flex={1}>
          <AsyncAutocomplete
            id="vehicleRegistration"
            name="vehicleRegistration"
            control={methods.control}
            label={values.requestType === requestTypeReplacement ? "Registration of replaced vehicle" : "Registration to replicate another vehicle"}
            apiEndpoint={apiEndpoint}
            onSelectedOptionChange={handleSelectedOptionChange}
            rules={{ required: false }}
            disabled={!values.requestType || notEditable}  
            value={values.vehicleRegistration || null}
            getOptionLabel={(option) => option.value}                          
          />
        </Stack>
      </Stack>
      {values.requestType === requestTypeNew && !values.lproceed && !values.vehicleRegistration && !loading &&
        <Stack flexDirection={{ xs: "column", md: "row" }} paddingTop={1}>
          <Typography variant="h6">
            Please enter a rego for the replicated or replaced vehicle or click proceed
          </Typography>
        </Stack>
      }
      {values.requestType === requestTypeReplacement && !values.vehicleRegistration && !loading &&
        <Stack flexDirection={{ xs: "column", md: "row" }} paddingTop={1}>
          <Typography variant="h6">
              Please enter a rego for the replicated or replaced vehicle
          </Typography>
        </Stack>
      }
      {loading && 
        <Stack flexDirection={{ xs: "column", md: "row" }} paddingTop={1}>
          <Spinner
            singleColor={theme.palette.black}
            size={18}
          />
          <Typography variant="h6" paddingLeft={1}>
            Loading Information
          </Typography>
        </Stack>
      }
      <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
        {(values.vehicleRegistration?.value || (data?.vehicleOrderDetails?.status && data?.vehicleOrderDetails?.status === 'Draft' && data?.vehicleOrderDetails?.vehicleRegistration)) && (
          <Stack width="50%">
            <Stack flex={1} paddingTop={2}>
              <Typography variant="h5" fontWeight={600}>
                {values.vehicleRegistration?.value ?? data?.vehicleOrderDetails?.vehicleRegistration} {values.year} {values.make1} {values.model1} {values.colour1}
              </Typography>
            </Stack>
            <Stack flex={1} paddingTop={2}>
              <Controller
                id="accountName1"
                name="accountName1"
                control={methods.control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={fleetOptions}
                    getOptionLabel={option => option.label || ""}
                    value={fleetOptions.find(option => option.value === values.accountName1) || null}
                    onSelect={(event, value) => {
                      onFleetChange(value ? value.value : "")
                    }}
                    onChange={(event, value) => {
                      onFleetChange(value ? value.value : "")
                    }}
                    renderInput={params => (
                      <MuiTextfield
                        {...params}
                        error={Boolean(methods.formState.errors?.accountName1?.message)}
                        label="Fleet*"
                        value={values.accountName1 || ""}
                      />
                    )}
                    disabled={true}
                  />
                )}
              />
            </Stack>
            <Stack flex={1} paddingTop={2}>
              <Controller
                name="leaseType1"
                control={methods.control}
                rules={{ required: false }}
                render={({ field }) => (
                  <MuiFormSelect
                    {...field}
                    id="leaseType1"
                    disabled={true}
                    label="Type of Lease"
                    labelId="leaseleaseType1-label"
                    options={["Fully Maintained Operating Lease", "Non Maintained Operating Lease"]}
                    value={values.leaseType1 || ''}
                  />
                )}
              />
            </Stack>
            <Stack flex={1} paddingTop={2}>
              <KeyValue
                id="term1"
                label='Term'
                disabled={true}
                value={values.term1 || ''}
              />
            </Stack>
            <Stack flex={1} paddingTop={2}>
              <KeyValue
                id="odometer1"
                label='Kms'
                disabled={true}
                value={values.odometer1 || ''}
              />
            </Stack>
            <Stack flex={1} paddingTop={2}>
              <KeyValue
                id="numberOfTyres1"
                label='Number of Tyres'
                disabled={true}
                value={values.numberOfTyres1 || ''}
              />
            </Stack>
          </Stack>
        )}
        {(values.lproceed || values.vehicleRegistration || data?.vehicleOrderDetails?.status) && (
          <Stack width="50%">
            <Stack flex={1} paddingTop={2}>
              <Typography variant="h5" fontWeight={600}>
                {"New Request"}
              </Typography>
            </Stack>      
            <Stack flex={1} paddingTop={2}>
              <Controller
                id="accountName"
                name="accountName"
                control={methods.control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={fleetOptions}
                    getOptionLabel={option => option.label || ""}
                    value={fleetOptions.find(option => option.value === values.accountName) || null}
                    onSelect={(event, value) => {
                      onFleetChange(value ? value.value : "")
                    }}
                    onChange={(event, value) => {
                      onFleetChange(value ? value.value : "")
                    }}
                    renderInput={params => (
                      <MuiTextfield
                        {...params}
                        error={Boolean(methods.formState.errors?.accountName?.message)}
                        label="Fleet*"
                        value={values.accountName || ""}
                      />
                    )}
                    disabled={notEditable}
                  />
                )}
              />
              <Typography color="error" fontSize={12}>
                {methods.formState.errors?.accountName?.message}
              </Typography>
            </Stack>      
            <Stack flex={1} paddingTop={2} direction="row" alignItems="center" spacing={1}>
              <Stack width="90%">
                <Controller
                  name="leaseType"
                  control={methods.control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <MuiFormSelect
                      {...field}
                      id="leaseType"
                      disabled={notEditable}
                      label="Type of Lease*"
                      labelId="leaseleaseType-label"
                      options={["Fully Maintained Operating Lease", "Non Maintained Operating Lease"]}
                      value={values.leaseType || ''}
                      onChange={(event) => {
                        const value = event?.target?.value;
                        onLeaseTypeChange(value);
                      }}
                      error={Boolean(methods.formState.errors?.leaseType?.message)}
                      helperText={methods.formState.errors?.leaseType?.message}
                    />
                  )}
                />
                <Typography color="error" fontSize={12}>
                  {methods.formState.errors?.leaseType?.message}
                </Typography>
              </Stack>
              <Stack width="10%">
                <IconButton onClick={() => setOpen(true)} size="small" color='primary'>
                  <InfoOutlinedIcon sx={{  fontSize: 'xx-large', alignItems: 'right'}} />
                </IconButton>
                <Dialog open={open} onClose={() => setOpen(false)}>
                  <DialogContent>
                    <LeaseTypeDetails onCancel={() => setOpen(false)}/>
                  </DialogContent>
                </Dialog>
              </Stack>
            </Stack>
            <Stack flex={1} paddingTop={2}>
              <Controller
                name="term"
                control={methods.control}
                rules={{ required: true }}
                render={({ field }) => (
                  <MuiTextfield
                    {...field}
                    id="term"
                    name="term"
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(methods.formState.errors?.term?.message)}
                    label="Term*"
                    value={values.term || ''}
                    onChange={handleInputChange}
                    disabled={notEditable}
                  />
                )}
              />
              <Typography color="error" fontSize={12}>
                {methods.formState.errors?.term?.message}
              </Typography>
            </Stack>
            <Stack flex={1} paddingTop={2}>
              <Controller
                name="odometer"
                control={methods.control}
                rules={{ required: true }}
                render={({ field }) => (
                  <MuiTextfield
                    {...field}
                    id="odometer"
                    name="odometer"
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(methods.formState.errors?.odometer?.message)}
                    label="Kms*"
                    value={values.odometer || ''}
                    onChange={(event) => {
                      const value = event?.target?.value;
                      onKmsChange(value);
                    }}
                    disabled={notEditable}
                  />
                )}
              />
              <Typography color="error" fontSize={12}>
                {methods.formState.errors?.odometer?.message}
              </Typography>
            </Stack>
            <Stack flex={1} paddingTop={2}>
              <Controller
                name="numberOfTyres"
                control={methods.control}
                rules={{ required: true }}
                render={({ field }) => (
                  <MuiTextfield
                    {...field}
                    id="numberOfTyres"
                    name="numberOfTyres"
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(methods.formState.errors?.numberOfTyres?.message)}
                    label="Number of Tyres"
                    value={values.numberOfTyres || ''}
                    onChange={handleInputChange}
                    disabled={values.leaseType === 'Non Maintained Operating Lease' || notEditable}
                  />
                )}
              />
              <Typography color="error" fontSize={12}>
                {methods.formState.errors?.numberOfTyres?.message}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
      <Stack paddingTop={2}>

      </Stack>
      {(values.vehicleRegistration?.value || (data?.vehicleOrderDetails?.status && data?.vehicleOrderDetails?.status === 'Draft' && data?.vehicleOrderDetails?.vehicleRegistration)) && (
        <Stack flexDirection={{ xs: "column", md: "row" }} gap={2} paddingBottom={2}>
          <Stack width={{ xs: "100%", md: "205px" }}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={handleCopyAllFields}
              disabled={notEditable}
            >
              Copy All Fields
            </Button>
          </Stack>
        </Stack>
      )}
      {(values.lproceed || values.vehicleRegistration || data?.vehicleOrderDetails?.status) && (
        <>
          <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
            <Stack width="50%">
              <Stack flex={1}>
                <KeyValue
                  id="driverName"
                  label='Driver Name'
                  value={values.driverName || ''}
                  inputProps={{ maxLength: 50 }}
                  onChange={onInputChange}
                  disabled={notEditable}
                />
              </Stack>
            </Stack>
            <Stack width="50%" flex={1}>
              <Controller
                name="driverEmail"
                control={methods.control}
                rules={{ required: true }}
                render={({ field }) => (
                  <MuiTextfield
                    {...field}
                    id="driverEmail"
                    name="driverEmail"
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(methods.formState.errors?.driverEmail?.message)}
                    label="Driver Email"
                    value={values.driverEmail || ''}
                    onChange={handleInputChange}
                    disabled={notEditable}
                  />
                )}
              />
              <Typography color="error" fontSize={12}>
                {methods.formState.errors?.driverEmail?.message}
              </Typography>
            </Stack>
          </Stack>
          <Stack flexDirection={{ xs: "column", md: "row" }} gap={2} paddingTop={2}>
            <Stack width="50%">
              <Stack flex={1}>
                <StaticAutocomplete
                  id="location"
                  name="location"
                  control={methods.control}
                  label={'Vehicle Delivery Location*'}
                  options={VehicleLocations}
                  onSelectedOptionChange={onLocationChange}
                  rules={{ required: true }}
                  disabled={notEditable}  
                  value={values.location || null}
                  getOptionLabel={(option) => option.value}
                  filterOptions={filterOptions}                          
                />
                <Typography color="error" fontSize={12}>
                  {methods.formState.errors?.location?.message}
                </Typography>
              </Stack>
            </Stack>
            <Stack width="50%" flex={1}>
              <Controller
                name="otherLocation"
                control={methods.control}
                rules={{ required: true}}
                render={({ field }) => (
                  <MuiTextfield
                    {...field}
                    id="otherLocation"
                    name="otherLocation"
                    inputProps={{ maxLength: 250 }}
                    error={Boolean(methods.formState.errors?.otherLocation?.message)}
                    label={values?.location?.value === 'Other' ? 'Vehicle Delivery Location Notes*': 'Vehicle Delivery Location Notes'}
                    value={values.otherLocation || ''}
                    onChange={handleInputChange}
                    disabled={notEditable}
                  />
                )}
              />
              <Typography color="error" fontSize={12}>
                {methods.formState.errors?.otherLocation?.message}
              </Typography>
            </Stack>
          </Stack>
          <Stack flexDirection={{ xs: "column", md: "row" }} gap={2} paddingTop={2}>
            <Stack width="100%">
              <Controller
                name="notes"
                control={methods.control}
                rules={{ required: false }}
                render={({ field }) => (
                  <MuiTextfield
                    {...field}
                    multiline
                    rows={4}
                    id="notes"
                    label="Notes"
                    inputProps={{ maxLength: 2000 }}
                    value={values.notes || ""}
                    onChange={onInputChange}
                    disabled={notEditable}
                  />
                )}
              />
            </Stack>
          </Stack>
        </>
      )}
      <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />
      {!notEditable &&
        <Stack
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          gap={1}
        >
          <Stack width={{ xs: "100%", md: "205px" }}>
            <Button
              fullWidth
              color="error"
              variant="outlined"
              onClick={() => setConfirmSaveDialogOpen(true)}
            >
              Close
            </Button>
          </Stack>
          <Stack
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            gap={1}
          >
            <Stack width={{ xs: "100%", md: "205px" }}>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="outlined"
                disabled={!values.requestType
                  || (values.requestType == requestTypeReplacement && !values.vehicleRegistration)
                  || (data?.vehicleOrderDetails?.status && data?.vehicleOrderDetails?.status == 'Submitted')}
              >
                Save and Exit
              </Button>
            </Stack>
            <Stack width={{ xs: "100%", md: "205px" }}>
              <Button
                fullWidth
                color="primary"
                id="lproceed"
                variant="contained"
                disabled={!values.requestType || (values.requestType == requestTypeReplacement && !values.vehicleRegistration)}
                onClick={handleProceed}
                value={values.lproceed}
              >
                Proceed
              </Button>
            </Stack>
          </Stack>
        </Stack>
      }
      <StyledModalV2
        open={confirmDialogOpen}
        onClose={() => {
          setConfirmDialogOpen(false);
          onClose();
        }}
        content={
          <Typography variant="h4" textAlign="center">
            {data?.vehicleOrderDetails?.status && data?.vehicleOrderDetails?.status == 'Draft' ? 'Vehicle order request updated successfully.' : 'Vehicle order request created successfully.'}
          </Typography>
        }
      />
      <StyledModalV2
        open={confirmSaveDialogOpen}
        onClose={() => setConfirmSaveDialogOpen(false)}
        content={
          <Typography variant="h4" textAlign="center">
            Any changes will not be saved. Are you sure you want to continue?
          </Typography>
        }
        actions={
          <StyledModalV2Actions
            onCancel={() => setConfirmSaveDialogOpen(false)}
            onConfirm={() => {
              setConfirmSaveDialogOpen(false);
              onClose();
            }}
          />
        }
      />
    </form>
  )
}

LeaseInformation.propTypes = {
  values: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  notEditable: PropTypes.bool,
}

export default LeaseInformation