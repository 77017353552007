import React from 'react'
import PropTypes from 'prop-types'
import { SingleSelect } from 'frame/components'
import { createOnFilter } from 'filter'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

const FilterAttributes = ({ filters, filtered, onFilter }) => {
  const onSelect = createOnFilter(onFilter)
  const hasList = (list) => list.length > 0
  const { costCentres, makes, models, expectedDeliveryDates, poRaisedDates, deliveryLocations } = filters
  // const statusOptions = ['Overdue', 'Upcoming', 'Planned']

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingY: 1,
          paddingX: 0,
          cursor: 'pointer',
        }}
      >
        <Typography
          variant="h5"
        >
          Filters
        </Typography>
      </Box>
      <Divider />
      <Box>
        {hasList(costCentres) && (
          <Box
            sx={{
              paddingY: 2,
              paddingX: 0
            }}
          >
            <SingleSelect
              tight
              id="costCentre"
              label="Cost Centre"
              options={costCentres}
              value={filtered.costCentre}
              onChange={onSelect('costCentre')}
             />
          </Box>
          )}
          {hasList(makes) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                tight
                id="make"
                label="Make"
                options={makes}
                value={filtered.make}
                onChange={onSelect('make')} 
              />
            </Box>
          )}
          {hasList(models) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                tight
                id="model"
                label="Model"
                options={models}
                value={filtered.model}
                onChange={onSelect('model')} />
            </Box>
          )}
          {hasList(expectedDeliveryDates) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                tight
                id="expectedDeliveryDate"
                label="Expected Delivery Date"
                options={expectedDeliveryDates}
                value={filtered.expectedDeliveryDate}
                onChange={onSelect('expectedDeliveryDate')} />
            </Box>
          )}
          {hasList(poRaisedDates) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                tight
                id="poRaisedDate"
                label="P.O raised"
                options={poRaisedDates}
                value={filtered.poRaisedDate}
                onChange={onSelect('poRaisedDate')} />
            </Box>
          )}
          {hasList(deliveryLocations) && (
            <Box
              sx={{
                paddingY: 2,
                paddingX: 0
              }}
            >
              <SingleSelect
                tight
                id="deliveryLocation"
                label="Delivery Location"
                options={deliveryLocations}
                value={filtered.deliveryLocation}
                onChange={onSelect('deliveryLocation')} />
            </Box>
          )}
        </Box>
    </Box>
  )
}

FilterAttributes.propTypes = {
  filters: PropTypes.object.isRequired,
  filtered: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default FilterAttributes
