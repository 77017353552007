const VehicleLocations = [
  { text: "Other", value: "Other" },
  { text: "Auckland East", value: "Auckland East", group: "North Island"},
  { text: "Auckland North", value: "Auckland North", group: "North Island" },
  { text: "Auckland South", value: "Auckland South", group: "North Island" },
  { text: "Auckland West", value: "Auckland West", group: "North Island" },
  { text: "Bay of Islands", value: "Bay of Islands", group: "North Island" },
  { text: "Bay of Plenty", value: "Bay of Plenty", group: "North Island" },
  { text: "Coromandel", value: "Coromandel", group: "North Island" },
  { text: "Gisborne", value: "Gisborne", group: "North Island" },
  { text: "Hamilton", value: "Hamilton", group: "North Island" },
  { text: "Hastings", value: "Hastings", group: "North Island" },
  { text: "Hawkes Bay", value: "Hawkes Bay", group: "North Island" },
  { text: "Lower Hutt", value: "Lower Hutt", group: "North Island" },
  { text: "Manawatu", value: "Manawatu", group: "North Island" },
  { text: "Manukau", value: "Manukau", group: "North Island" },
  { text: "Mount Maunganui", value: "Mount Maunganui", group: "North Island" },
  { text: "Napier", value: "Napier", group: "North Island" },
  { text: "Northland", value: "Northland", group: "North Island" },
  { text: "Palmerston North", value: "Palmerston North", group: "North Island" },
  { text: "Rotorua", value: "Rotorua", group: "North Island" },
  { text: "Ruapehu", value: "Ruapehu", group: "North Island" },
  { text: "Taranaki", value: "Taranaki", group: "North Island" },
  { text: "Taupo", value: "Taupo", group: "North Island" },
  { text: "Tauranga", value: "Tauranga", group: "North Island" },
  { text: "Upper Hutt", value: "Upper Hutt", group: "North Island" },
  { text: "Wairarapa", value: "Wairarapa", group: "North Island" },
  { text: "Wanganui", value: "Wanganui", group: "North Island" },
  { text: "Wellington City", value: "Wellington City", group: "North Island" },
  { text: "Whakatane", value: "Whakatane", group: "North Island" },
  { text: "Whangarei", value: "Whangarei", group: "North Island" },
  { text: "Blenheim", value: "Blenheim", group: "South Island" },
  { text: "Canterbury", value: "Canterbury", group: "South Island" },
  { text: "Christchurch", value: "Christchurch", group: "South Island" },
  { text: "Collingwood", value: "Collingwood", group: "South Island" },
  { text: "Cromwell", value: "Cromwell", group: "South Island" },
  { text: "Dunedin", value: "Dunedin", group: "South Island" },
  { text: "Fiordland", value: "Fiordland", group: "South Island" },
  { text: "Greymouth", value: "Greymouth", group: "South Island" },
  { text: "Invercargill", value: "Invercargill", group: "South Island" },
  { text: "Kaikoura", value: "Kaikoura", group: "South Island" },
  { text: "Marlborough", value: "Marlborough", group: "South Island" },
  { text: "Milford Sounds", value: "Milford Sounds", group: "South Island" },
  { text: "Mount Cook", value: "Mount Cook", group: "South Island" },
  { text: "Nelson", value: "Nelson", group: "South Island" },
  { text: "Oamaru", value: "Oamaru", group: "South Island" },
  { text: "Otago", value: "Otago", group: "South Island" },
  { text: "Queenstown", value: "Queenstown", group: "South Island" },
  { text: "Southland", value: "Southland", group: "South Island" },
  { text: "Tasman", value: "Tasman", group: "South Island" },
  { text: "Timaru", value: "Timaru", group: "South Island" },
  { text: "Wanaka", value: "Wanaka", group: "South Island" },
  { text: "West Coast", value: "West Coast", group: "South Island" },
  { text: "Westport", value: "Westport", group: "South Island" },
  { text: "Whataroa", value: "Whataroa", group: "South Island" }
];

export default VehicleLocations
