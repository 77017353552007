import * as z from "zod";

export const schema = z.object({
  requestType: z
    .string()
    .optional(),
  accountName1: z
    .string()
    .optional(),
  leaseType1: z
    .string()
    .optional(),
  term1: z
    .number()
    .optional(),
  odometer1: z
    .string()
    .optional(),
  numberOfTyres1: z
    .string()
    .optional(),
  accountName: z
    .string({ required_error: "Fleet is required" })
    .min(1, { message: "Fleet is required" }),
  leaseType: z
    .string({ required_error: "Lease Type is required" })
    .min(1, { message: "Lease Type is required" }),
  term: z
    .string()
    .min(1, { message: "Term is required" })
    .refine(value => /^\d+$/.test(value), {
      message: 'Invalid number',
    }),
  odometer: z
    .string({ required_error: "Kms is required" })
    .min(1, { message: "Kms is required" })
    .refine(value => /^\d+$/.test(value), {
      message: 'Invalid number',
    }),
  numberOfTyres: z
    .string()
    .refine(value => /^\d+$/.test(value), {
      message: 'Invalid number',
    })
    .optional()
    .or(z.literal('')),
  driverName: z
    .string()
    .optional(),
  driverEmail: z
    .string()
    .email("Must be a valid email")
    .optional()
    .or(z.literal('')),
  location: z
    .string({ required_error: "Delivery Location is required" })
    .min(1, { message: "Delivery Location is required" }),
  otherLocation: z
    .string()
    .optional(),
  notes: z
    .string()
    .optional(),
})
.superRefine((data, ctx) => {
  if (data.location === "Other") {
    if (data.otherLocation === undefined || data.otherLocation === null || data.otherLocation === '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Vehicle Delivery Location Notes are required",
        required_error: "Delivery Location Notes are required",
        path: ["otherLocation"],
      });
    }
  }
});
