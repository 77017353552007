import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { getRole } from 'listable'
import { hasRoles } from 'acl'
import { types } from '../../module'
import { Button, Card, CardContent, Grid, Typography, TextField, Checkbox, FormControlLabel, IconButton } from '@material-ui/core'
import { Alert, SingleSelectValidator, StyledModal } from 'frame/components'
import { isBornUser } from 'utils'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  error: {
    marginBottom: theme.spacing(4),
  },
  content: {
    padding: theme.spacing(3, 1.2, 0, 1.2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
  fieldHeader: {
    marginTop: theme.spacing(3),
  },
  fieldGroup: {
    margin: theme.spacing(2, 0),
  },
  fieldGroupOptions: {
    margin: theme.spacing(2, 0),
    maxHeight: 300,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    }
  },
  selectAllBtn: {
    minWidth: 180,
    [theme.breakpoints.down('xs')]: { minWidth: '100%' }
  },
  errorText: {
    color: theme.palette.error.main,
    position: 'absolute',
    zIndex: 99
  },
  scrollButtons: { marginLeft: 'auto', marginRight: 0, marginTop: 10 },
  scrollButton: {
    backgroundColor: 'transparent',
    '&:hover': { backgroundColor: '#bdbdbd' }
  }
}))

const Form = ({
  error,
  data = {},
  disabled,
  fleets,
  roles,
  userRoles,
  onCancel,
  onDelete,
  onBlock,
  onSubmit,
  onChange,
  currentUser
}) => {

  const classes = useStyles()
  const form = useRef()
  const topRef = useRef()
  const bottomRef = useRef()

  const [fleetIds, setFleetIds] = useState(data?.fleets || []) // for fleets checkbox state
  const [selectAll, setSelectAll] = useState(data?.fleets.length > 0 ? data?.fleets.length === fleetIds?.length : false)

  useEffect(() => { // fixes late fleet initializing issues
    data?.fleets && setFleetIds(data?.fleets)
  }, [data?.fleets]) // eslint-disable-line

  
  //for delete/user deactivation confirmation
  const [openDeactivationModal, setOpenDeactivationModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [showRequiredFleetsError, setShowRequiredFleetsError] = useState(false)

  // fleet is required except for SuperAdmin Role
  const isValidFleetSelction = (data.role && data.role.name === 'SuperAdmin') || fleetIds.length > 0

  const isSelected = fleetId => JSON.stringify(data.fleets).includes(fleetId)  

  const onInputChange = ({ target: { id, name, value } }) =>
    onChange({ ...data, [id || name]: value })

  const onSelectAll = () => {
    const allFleets = fleets.map(node => ({ id: node.value, name: node.label }))
    setFleetIds(selectAll ? [] : allFleets)
    onChange({ ...data, fleets: selectAll ? [] : allFleets })
    setSelectAll(!selectAll)
  }

  const onSelectRoleChange = value => {
    let role = roles.filter(node => node.id === value)[0]
    onChange({ ...data, role })
  }

  const onSelect = (fleetId) => () => {  
    if (isSelected(fleetId)) {
      const fleetRemoved = fleetIds && fleetIds.filter(node => node.id !== fleetId)
      setFleetIds(fleetRemoved)
      onChange({ ...data, fleets: fleetRemoved })
      return
    }

    let fleet = fleets && fleets.filter(node => node.value === fleetId)[0]
    let fleetToAdd = { id: fleet.value, name: fleet.label }
    setFleetIds(fleetIds.concat(fleetToAdd))
    onChange({ ...data, fleets: fleetIds.concat(fleetToAdd) })
  }

  const onSaveValidation = () => {
    if (form.current) {
      form.current.submit()
      !isValidFleetSelction && setShowRequiredFleetsError(true)
    }
  }

  const onSubmitForm = () => isValidFleetSelction &&   
    onSubmit({
      ...data,
      nickname: data.nickname.trim(),
      fleets: fleetIds,
    })

  const roleOptions = roles && roles.map(node => ({
    label: getRole(node.name),
    value: node.id,
  }))
  
  const onDeactivateUser = () => {
    onBlock(`?userId=${data.id}&block=${!data.blocked}`)
    onCancel()
  }

  const onDeleteUser = () => {
    onDelete(data.id)
    onCancel()
  }

  useEffect(() => {
    fleetIds.length > 0 && setShowRequiredFleetsError(false)
  }, [fleetIds])

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidEmail', (value) => {

      if(value === '') return true
      
      let domain = value.split(/@/)[1]
      let ext = domain && domain.split(/\./)[1]
      let isValidDomain = ext && ext.length > 1 // to avoid single character email extension 
      let isEmailFormat = /^[^\s@]{2,}@[^\s@]{2,}\.[^\s@]{2,}$/.test(value)

      return isEmailFormat && isValidDomain
    })
    return () => {
      ValidatorForm.removeValidationRule('isValidEmail')
    }
  }, [data.email])

  const onScroll = (ref) => () => ref.current && ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

  return (
    <ValidatorForm autoComplete="off" noValidate ref={form} onSubmit={onSubmitForm}>
      <Card>
        <CardContent className={classes.content}>
          {error && (
            <Alert className={classes.error}>
              Something went wrong whilst trying to add this user.
            </Alert>
          )}

          <Typography gutterBottom variant="h4">
            Details
          </Typography>
          <div className={classes.fieldGroup}>
            <Grid container spacing={4}>
              <Grid item sm={6} xs={12}>
                <TextValidator
                  id="nickname"
                  className={classes.field}
                  disabled={disabled}
                  fullWidth
                  label="Name"
                  inputProps={{ maxLength: 50 }}
                  value={data.nickname || ''}
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={onInputChange} />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextValidator
                  id="email"
                  className={classes.field}
                  disabled={disabled}
                  fullWidth
                  label="Email"
                  value={data.email || ''}
                  variant="outlined"
                  validators={['required', 'isValidEmail']}
                  errorMessages={['This field is required', 'Must be a valid email']}
                  onChange={onInputChange} />
              </Grid>
            </Grid>
          </div>
          
          {/* Fleet Selector Section */}
          <Grid className={classes.fieldHeader} alignItems="flex-end" container justify="space-between" spacing={2}>
            <Grid item>
              <Typography gutterBottom variant="h4">
                Fleets
              </Typography>
              <Typography variant="body2">
              Please select the fleets you wish to view data for.
              </Typography>
            </Grid>
            <Grid item >
              <Button
                id={selectAll  ? 'unselect-btn' : 'select-btn'}
                variant="contained"
                className={classes.selectAllBtn}
                onClick={() => onSelectAll()}>
                {selectAll ? 'Unselect All' : 'Select All'}
              </Button>
            </Grid>
          </Grid>

          <div className={classes.fieldGroupOptions}>
            <Grid container>
              <style scoped>
                {` #checkbox-form-control > .MuiCheckbox-root { color: ${showRequiredFleetsError ? '#e53935' : '#546E7A'}; } `}
              </style>
              {fleets.map((node, index) => (
                  <Grid key={index} item sm={6} xs={12}>

                    {index === 0 && <div ref={topRef} />}

                    <FormControlLabel
                      id="checkbox-form-control"
                      control={
                        <Checkbox
                          checked={isSelected(node.value)}
                          onChange={onSelect(node.value)}
                          value={node.value}
                          color="default" />
                      }
                      label={node.label} />

                      {index === fleets.length - 1 && <div ref={bottomRef} />}
                  </Grid>
                )
              )}
            </Grid>
            {showRequiredFleetsError && (
              <p className={classes.errorText}>*Please select atleast 1 fleet</p>
            )}
          </div>
          {/* End of Fleet Selector Section */}

          <div className={classes.fieldHeader}>
            <Typography gutterBottom variant="h4">
              Role
            </Typography>
            <Typography variant="body2">
              Please select the role this user will have for all the selected fleets.
            </Typography>
          </div>

          <div className={classes.fieldGroup}>
            <Grid container spacing={4}>
              <Grid item sm={6} xs={12}>
                <SingleSelectValidator
                  id={`role`}
                  disabled={disabled}
                  label="Role"
                  options={roleOptions || []}
                  value={(data.role && data.role.id) || ''}
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={onSelectRoleChange} />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  id="status"
                  className={classes.field}
                  disabled={true}
                  fullWidth
                  label="Status"
                  value={data.blocked ? 'Blocked' : 'Active'}
                  variant="outlined" />
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
      

      <div className={classes.actions}>
        <Button
          className={classes.button}
          color="primary"
          disabled={disabled}
          variant="contained"
          onClick={onSaveValidation}>
          Save Changes
        </Button>

        {hasRoles(['superadmin', 'fleetcontroller'], userRoles) && (
          <Button
            className={classes.button}
            disabled={disabled}
            variant="contained"
            onClick={() => setOpenDeactivationModal(true)}>
            {data.blocked ? 'Activate User' : 'Deactivate User'}
          </Button>
        )}
        
        {/* for born users only for easy maintenance */}
        {hasRoles(['superadmin'], userRoles) && isBornUser(currentUser.email) 
          && !isBornUser(data.email) // prevent deleting main born accounts 
          && (
            <Button
              className={classes.button}
              disabled={disabled}
              variant="contained"
              onClick={() => setOpenDeleteModal(true)}>
              Delete User
            </Button>
        )}

        <Button className={classes.button} disabled={disabled} variant="contained" onClick={onCancel}>
          Cancel
        </Button>
      
        <div className={classes.scrollButtons}>
          <IconButton color="secondary" onClick={onScroll(topRef, 'top')} className={classes.scrollButton}>
            <ArrowUpwardIcon fontSize="small" />
          </IconButton>
          <IconButton color="secondary" onClick={onScroll(bottomRef, 'bottom')} className={classes.scrollButton}>
            <ArrowDownwardIcon fontSize="small" />
          </IconButton>
        </div>
      </div>

      {/* for user deactivation */}
      <StyledModal
        open={openDeactivationModal}
        title={`Confirm User ${data.blocked ? 'Activation' : 'Deactivation'}`}
        onCancel={() => setOpenDeactivationModal(false)}
        onConfirm={onDeactivateUser}>
        {`Please confirm to ${data.blocked ? 'activate' : 'deactivate'} user ${data.nickname}.`}
      </StyledModal>

      {/* for delete */}
      <StyledModal
        open={openDeleteModal}
        title={`Confirm Delete User`}
        onCancel={() => setOpenDeleteModal(false)}
        onConfirm={onDeleteUser}>
        {`Please confirm to delete user ${data.nickname}.`}
      </StyledModal>
    </ValidatorForm>
  )
}

Form.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  data: PropTypes.oneOfType([
    PropTypes.object,
    types.ModelType,
  ]).isRequired,
  fleets: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onBlock: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentUser: PropTypes.object.isRequired,
}

export default Form
