import React, { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Dialog } from 'frame/components'
import { useNavigate, useRouter } from 'utils'
import { constants } from '../../module'
import Form from '../new/form'
import TabMenu from "../../../../features/fleet/components/drill-down-modal/tab-menu"
import { formTabs } from "../../config"
import StyledModalV2 from 'frame/components/styled-modal-v2'
import Typography from '@mui/material/Typography'

export const ItemVehicleSummaryContext = createContext({})

const Item = ({
  id,
  data,
  fleetIds,
  fetchStatus,
  saveStatus,
  userRoles,
  onFetch,
  onUpdate,
  onDelete,
}) => {
  const navigate = useNavigate()
  const { match } = useRouter()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [status, setStatus] = useState('Draft');
  const [activeTab, setActiveTab] = useState(() => {
    const tabFromUrl = window.location.pathname?.split('/').pop();
      return tabFromUrl || formTabs[0].value;
  });
  const {vehicleOrderDetails} = data;

  const onClose = navigate(constants.PATH_ALL)

  const onSave = (payload) => {
    setConfirmDialogOpen(true)
    if(payload?.status === "Submitted"){
      setStatus(payload?.status)
    }
    onUpdate(payload);
  }

  // Same as componentDidMount
  // We either use material ui 'useStyles' or we wrap the component
  // in an HoC and pass the styles down or completely
  // redo the way styles are done if we want to change this.
  useEffect(() => {
    onFetch(id, fleetIds)
    if(!confirmDialogOpen) {
      navigate(`${match.url}${constants.PATH_LEASE_INFORMATION}`)(0)
    }
  }, [id, fleetIds]) // eslint-disable-line

  useEffect(() => {
    if(!confirmDialogOpen) {
      const tabFromUrl = window.location.pathname?.split('/').pop();
      setActiveTab(tabFromUrl || formTabs[0].value);
    }
  }, [window.location.pathname]);

  const isLoading = fetchStatus === 'processing' && ! data
  const isSaving = saveStatus === 'processing'
  const hasErrored = fetchStatus === 'error'
  const savingError = saveStatus === 'error'

  const loadingErrorMessage = hasErrored
    ? 'Whoops, we could not find that request order booking right now.'
    : null

  const tabsConfig = [
    {
      label: 'Lease Information',
      path: `${match.url}${constants.PATH_LEASE_INFORMATION}`,
      visible: vehicleOrderDetails?.status === 'Submitted' ? false : true,
      isDataAvailable: (vehicleOrderDetails?.requestType &&
        vehicleOrderDetails?.accountName &&
        vehicleOrderDetails?.leaseType &&
        vehicleOrderDetails?.term &&
        vehicleOrderDetails?.odometer &&
        vehicleOrderDetails?.location &&
        ((vehicleOrderDetails?.location === 'Other' && vehicleOrderDetails?.otherLocation) || vehicleOrderDetails?.location !== 'Other')),
    }, {
      label: 'Vehicle Information',
      path: `${match.url}${constants.PATH_VEHICLE_INFORMATION}`,
      visible: vehicleOrderDetails?.status === 'Submitted' ? false : true,
      isDataAvailable: (vehicleOrderDetails?.make && vehicleOrderDetails?.model),
    }, {
      label: 'Additional Services',
      path: `${match.url}${constants.PATH_ADDITIONAL_SERVICES}`,
      visible: vehicleOrderDetails?.status === 'Submitted' ? false : true,
      isDataAvailable: (vehicleOrderDetails?.hasFuelCardAndReporting 
        || vehicleOrderDetails?.hasFuelCardAndReporting
        || vehicleOrderDetails?.hasReliefVehicle
        || vehicleOrderDetails?.hasRucManagement
        || vehicleOrderDetails?.hasAccidentManagement
        || vehicleOrderDetails?.hasTollManagement
        || vehicleOrderDetails?.hasInfringementManagement
        || vehicleOrderDetails?.hasFbtReporting
        || vehicleOrderDetails?.hasTelematics
        || vehicleOrderDetails?.hasFleetPortal
        || vehicleOrderDetails?.hasDriverApp
        || vehicleOrderDetails?.hasRoadsideAssistance
        || vehicleOrderDetails?.additionalServiceNotes ),
    }, {
      label: 'Accessories',
      path: `${match.url}${constants.PATH_ACCESSORIES}`,
      visible: vehicleOrderDetails?.status === 'Submitted' ? false : true,
      isDataAvailable: (vehicleOrderDetails?.hasCanopySlideWindows
        || vehicleOrderDetails?.hasCanopyLiftupWindows
        || vehicleOrderDetails?.hasCentralLockingForCanopySelection
        || vehicleOrderDetails?.hasRubberMats
        || vehicleOrderDetails?.hasCarpetMats
        || vehicleOrderDetails?.hasDeckliner
        || vehicleOrderDetails?.hasHardlid
        || vehicleOrderDetails?.hasSoftTonneauCover
        || vehicleOrderDetails?.hasTowbarWiring
        || vehicleOrderDetails?.hasRoofRacks
        || vehicleOrderDetails?.hasSeatCovers
        || vehicleOrderDetails?.hasFirstAidKit
        || vehicleOrderDetails?.hasFireExtinguisher
        || vehicleOrderDetails?.hasCargoBarrier
        || vehicleOrderDetails?.hasTyreUpgrade
        || vehicleOrderDetails?.hasSignWriting
        || vehicleOrderDetails?.hasSportsBar
        || vehicleOrderDetails?.hasNudgeBar
        || vehicleOrderDetails?.canopySlideWindowsNotes
        || vehicleOrderDetails?.canopyLiftupWindowsNotes
        || vehicleOrderDetails?.centralLockingForCanopySelectionNotes
        || vehicleOrderDetails?.rubberMatsNotes
        || vehicleOrderDetails?.carpetMatsNotes
        || vehicleOrderDetails?.decklinerNotes
        || vehicleOrderDetails?.hardlidNotes
        || vehicleOrderDetails?.softTonneauCoverNotes
        || vehicleOrderDetails?.towbarWiringNotes
        || vehicleOrderDetails?.roofRacksNotes
        || vehicleOrderDetails?.seatCoversNotes
        || vehicleOrderDetails?.firstAidKitNotes
        || vehicleOrderDetails?.fireExtinguisherNotes
        || vehicleOrderDetails?.cargoBarrierNotes
        || vehicleOrderDetails?.tyreUpgradeNotes
        || vehicleOrderDetails?.signWritingNotes
        || vehicleOrderDetails?.sportsBarNotes
        || vehicleOrderDetails?.nudgeBarNotes )
    },
  ].filter(x => x.visible)

  return (
    <>
      <Dialog
        open={!confirmDialogOpen}
        autoSize
        size="md"
        aria-labelledby="Request Vehicle Order"
        breadcrumbs=""
        title={`Request Vehicle Order`}
        loading={isLoading }
        onClose={onClose}
        tabsConfig={tabsConfig}
        tabMenu={<TabMenu config={tabsConfig} />}
      >
        <Grid container>
          <Grid item md={12} xs={12}>
            <ItemVehicleSummaryContext.Provider value={{
              activeTab,
              setActiveTab,
            }}>
              <Form
                data={data}
                disabled={isLoading || isSaving}
                error={savingError}
                errorMessage={loadingErrorMessage}
                userRoles={userRoles}
                onClose={onClose}
                onSubmit={onSave}
                onDelete={onDelete}
                activeTab={activeTab}
                isEdit={data?.vehicleOrderDetails?.status === "Draft"}
              />
            </ItemVehicleSummaryContext.Provider>
          </Grid>
        </Grid>
      </Dialog>
      <StyledModalV2
        open={confirmDialogOpen}
        onClose={() => {
          setConfirmDialogOpen(false);
          onClose();
        }}
        content={
          <Typography variant="h4" textAlign="center">
            {data?.vehicleOrderDetails?.status
              && data?.vehicleOrderDetails?.status === 'Draft'
              && status === 'Draft'
               ? 'Vehicle order request updated successfully.'
               : 'Vehicle order request submitted successfully.'}
          </Typography>
        }
      />
    </>
  )
}

Item.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  data: PropTypes.object,
  fleetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchStatus: PropTypes.string,
  saveStatus: PropTypes.string,
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFetch: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
}

export default Item