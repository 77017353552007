import React, {useContext} from 'react'
import {Stack, TextField} from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useTheme } from '@mui/material/styles'
import MuiSwitch from 'frame/components/switch'
import PropTypes from 'prop-types'
import { FormContext } from '../form'

export const AccessoriesForm = ({ name, label, notesname, disabled }) => {
  const formContext = useContext(FormContext);
  const { values, onInputChange, onSwitchChange } = formContext;
  const theme = useTheme()

  return (
    <Stack flexDirection="row" alignItems="center">
      <FormControlLabel
        control={
          <MuiSwitch
            name={name}
            checked={Boolean(values[name]) || false}
            onChange={onSwitchChange(name)}
            disabled={disabled}
          />
        }
        label={label}
      />
      <TextField
        placeholder="Notes"
        id={notesname}
        size="small"
        inputProps={{ maxLength: 255 }}
        sx={{
          minHeight: "20px",
          width: "100%",
          "& fieldset": {
            border: "none",
          },
        }}
        InputProps={{
          sx: {
            minHeight: "20px",
            borderRadius: "5px",
            bgcolor: theme.palette.neutral.mid,
            border: "none",
          },
        }}
        value={values[notesname] || ''}
        onChange={onInputChange}
        disabled={disabled}
      />
    </Stack>
  );
};

AccessoriesForm.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  notesname: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onSwitchChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
}