import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useRouter } from 'utils'
import { constants } from '../../../module'
import StyledModalV2, { StyledModalV2Actions } from 'frame/components/styled-modal-v2'

import { NewVehicleSummaryContext } from '../new'
import { FormContext } from '../form'

import Button from "@mui/material/Button"
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import MuiFormSelect from 'frame/components/mui-form-select'
import KeyValue from 'frame/components/mui-textfield'
import { useTheme } from '@mui/material/styles'
import MuiTextfield from 'frame/components/mui-textfield'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { schema } from './form-schema'
import { Alert } from 'frame/components'
import { useSelectedFleets } from "features/settings"

const VehicleInformation = ({ 
  onSubmit,
  notEditable,
  error,
  errorMessage
}) => {
  const newContext = useContext(NewVehicleSummaryContext);
  const formContext = useContext(FormContext);
  const theme = useTheme()
  const navigate = useNavigate()
  const { match } = useRouter()

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmSaveDialogOpen, setConfirmSaveDialogOpen] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  const userSelectedFleets = useSelectedFleets()

  const methods = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "all",
  })

  const requestTypeNew = 'New/Extra vehicle';

  const {
    values,
    onInputChange,
    data,
    setValues,
  } = formContext

  const isEdit = data?.vehicleOrderDetails?.status === "Draft" || data?.vehicleOrderDetails?.status === null

  const handleCopyAllFields = () => {
    setValues((prevValues) => ({
      ...prevValues,
      make : values.make1,
      model : values.model1,
      variant : values.variant1,
      colour : values.colour1,
      driveTrain : values.driveTrain1
    }));
  };

  const onClose = navigate(constants.PATH_ALL)

  const handleConfirmClose = () => {
    setConfirmDialogOpen(false);
    navigate(constants.PATH_ALL);
  };

  const handleProceed = () => {
    if(!(data?.vehicleOrderDetails?.status && data?.vehicleOrderDetails?.status === 'Draft')){
      newContext.setValues(values)
    }
    navigate(`${match.url}${constants.PATH_ADDITIONAL_SERVICES}`)(0);
  }

  const onSubmitForm = () => {
    if(values.vehicleRegistration && typeof values.vehicleRegistration === 'object' && values.vehicleRegistration !== null ) {
      values.vehicleRegistration = values.vehicleRegistration?.value;
    }
    if(values.location && typeof values.location === 'object' && values.location !== null ) {
      values.location = values.location?.value;
    }
    if(values.thirdPartyNumber && values.accountName){
      const fleet = userSelectedFleets.filter(f => f.value === values.thirdPartyNumber)[0]
      const accountName = fleet ? fleet.label : null
      values.accountName = accountName;
    }
    if (values.requestType 
      && values.leaseType
      && values.accountName
      && values.term
      && values.odometer
      && values.location
      && values.location?.value != ''
      && ((values.location === 'Other' && values.otherLocation) || values.location !== 'Other')
    ) {
      onSubmit(values)
      setConfirmDialogOpen(true);
    } else {
      setValidationMessage('Please complete mandatory fields* in Lease Information tab.')
    }
  }

  const handleInputChange = ({ target: { id, name, value } }) => {
    setValues({ ...values, [id || name]: value })
  }

  useEffect(() => {
    if (!data) {
      return
    }
    const vehicleOrderDetails = data?.vehicleOrderDetails;
    setValues((values) => ({
      ...values,
      make: vehicleOrderDetails.make && !values.make ? vehicleOrderDetails.make : values.make,
      make1: vehicleOrderDetails?.referencedVehicleData?.make,
      model: vehicleOrderDetails.model && !values.model ? vehicleOrderDetails.model : values.model,
      model1: vehicleOrderDetails?.referencedVehicleData?.model,
      variant: vehicleOrderDetails.variant && !values.variant ? vehicleOrderDetails.variant : values.variant,
      variant1: vehicleOrderDetails?.referencedVehicleData?.variant,
      colour: vehicleOrderDetails.colour && !values.colour ? vehicleOrderDetails.colour : values.colour,
      colour1: vehicleOrderDetails?.referencedVehicleData?.colour,
      secondaryColour: vehicleOrderDetails.secondaryColour && !values.secondaryColour ? vehicleOrderDetails.secondaryColour : values.secondaryColour,
      driveTrain: vehicleOrderDetails.driveTrain && !values.driveTrain ? vehicleOrderDetails.driveTrain : values.driveTrain,
      driveTrain1: "Petrol",
      vehicleNotes: vehicleOrderDetails.vehicleNotes && !values.vehicleNotes ? vehicleOrderDetails.vehicleNotes : values.vehicleNotes,

      id: vehicleOrderDetails?.id,
    }))
  }, [data])

  return (
    <form
      id="request-vehicle-information-form"
      onSubmit={methods.handleSubmit(onSubmitForm)}
    >
      <Stack>
        {validationMessage && (
          <Stack gap={1}>
            <Alert>
              {validationMessage || 'Something went wrong while trying to submit vehicle order request.' }
            </Alert>
          </Stack>
        )}
        <Stack flexDirection={{ xs: "column", md: "row" }} gap={2}>
          <>
          {(values.vehicleRegistration?.value || (data?.vehicleOrderDetails?.status && data?.vehicleOrderDetails?.status === 'Draft' && data?.vehicleOrderDetails?.vehicleRegistration)) && (
              <Stack width="50%">
                <Stack flex={1}>
                  <Typography variant="h5" fontWeight={600}>
                    {values?.vehicleRegistration?.value} {values?.year} {values?.make1} {values?.model1} {values?.colour1}
                  </Typography>
                </Stack>
                <Stack flex={1}>
                  <KeyValue
                    id="make1"
                    label='Make'
                    disabled={true}
                    value={values.make1 || ''}
                  />
                </Stack>
                <Stack flex={1} paddingTop={2}>
                  <KeyValue
                    id="model1"
                    label='Model'
                    disabled={true}
                    value={values.model1 || ''}
                  />
                </Stack>
                <Stack flex={1} paddingTop={2}>
                  <KeyValue
                    id="variant1"
                    label='Variant'
                    disabled={true}
                    value={values.variant1 || ''}
                  />
                </Stack>
                <Stack flex={1} paddingTop={2}>
                  <KeyValue
                    id="colour1"
                    label='Colour'
                    disabled={true}
                    value={values.colour1 || ''}
                  />
                </Stack>
                <Stack flex={1} paddingTop={2}>
                </Stack>
                <Stack flex={1} paddingTop={4}>
                  <MuiFormSelect
                    id="driveTrain1"
                    disabled={true}
                    label="Drive Train"
                    labelId="driveTrain1-label"
                    options={["Petrol", "Diesel", "Hybrid", "Plug-in Hybrid", "Electric"]}
                    value={values.driveTrain1 || ''}
                  />
                </Stack>
              </Stack>
            )}
          </>
          <Stack width="50%">
            <Stack flex={1}>
              <Typography variant="h5" fontWeight={600}>
                {"New Request"}
              </Typography>
            </Stack>
            <Stack flex={1} paddingTop={2}>
              <Controller
                name="make"
                control={methods.control}
                rules={{ required: false }}
                render={({ field }) => (
                  <MuiTextfield
                    {...field}
                    id="make"
                    name="make"
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(methods.formState.errors?.make?.message)}
                    label="Make*"
                    value={values.make || ''}
                    onChange={handleInputChange}
                    disabled={notEditable}
                  />
                )}
              />
            </Stack>
            <Stack flex={1} paddingTop={2}>
            <Controller
                name="model"
                control={methods.control}
                rules={{ required: false }}
                render={({ field }) => (
                  <MuiTextfield
                    {...field}
                    id="model"
                    name="model"
                    inputProps={{ maxLength: 50 }}
                    error={Boolean(methods.formState.errors?.model?.message)}
                    label="Model*"
                    value={values.model || ''}
                    onChange={handleInputChange}
                    disabled={notEditable}
                  />
                )}
              />
            </Stack>
            <Stack flex={1} paddingTop={2}>
              <KeyValue
                id="variant"
                label='Variant'
                value={values.variant || ''}
                inputProps={{ maxLength: 50 }}
                onChange={onInputChange}
                disabled={notEditable}
              />
            </Stack>
            <Stack flex={1} paddingTop={2}>
              <KeyValue
                id="colour"
                label='Colour'
                value={values.colour || ''}
                inputProps={{ maxLength: 50 }}
                onChange={onInputChange}
                disabled={notEditable}
              />
            </Stack>
            <Stack flex={1} paddingTop={2}>
              <KeyValue
                id="secondaryColour"
                label='Secondary Colour'
                value={values.secondaryColour || ''}
                inputProps={{ maxLength: 50 }}
                onChange={onInputChange}
                disabled={notEditable}
              />
            </Stack>
            <Stack flex={1} paddingTop={2}>
              <MuiFormSelect
                id="driveTrain"
                disabled={notEditable}
                label="Drive Train"
                labelId="driveTrain-label"
                options={["Petrol", "Diesel", "Hybrid", "Plug-in Hybrid", "Electric"]}
                value={values.driveTrain || ''}
                onChange={( event ) => {
                  setValues({
                    ...values,
                    driveTrain: event?.target?.value
                  })
                }}
              />
            </Stack>
          </Stack>
        </Stack>

        <>
          {(values.requestType !== requestTypeNew || values.vehicleRegistration) && (
            <Stack flexDirection={{ xs: "column", md: "row" }} gap={2} paddingTop={2} paddingBottom={2}>
              <Stack width={{ xs: "100%", md: "205px" }}>
                <Button
                  fullWidth
                  color="primary"
                  variant="outlined"
                  onClick={handleCopyAllFields}
                  disabled={notEditable}
                >
                  Copy All Fields
                </Button>
              </Stack>
            </Stack>
          )}
        </>
        <Stack flexDirection={{ xs: "column", md: "row" }} gap={2} paddingTop={2}>
          <Stack width="100%">
            <Controller
              name="vehicleNotes"
              control={methods.control}
              rules={{ required: false }}
              render={({ field }) => (
                <MuiTextfield
                  {...field}
                  multiline
                  rows={4}
                  id="vehicleNotes"
                  label="Notes"
                  inputProps={{ maxLength: 2000 }}
                  value={values.vehicleNotes || ""}
                  onChange={onInputChange}
                  disabled={notEditable}
                />
              )}
            />
          </Stack>
        </Stack>
        <Divider sx={{ mt: 3, mb: 2, borderColor: theme.palette.accent.sky }} />
        {!notEditable &&
          <Stack
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            gap={1}
          >
            <Stack width={{ xs: "100%", md: "205px" }}>
              <Button
                fullWidth
                color="error"
                variant="outlined"
                onClick={() => setConfirmSaveDialogOpen(true)}
              >
                Close
              </Button>
            </Stack>
            <Stack
              flexDirection={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              gap={1}
            >
              <Stack width={{ xs: "100%", md: "205px" }}>
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="outlined"
                >
                  Save and Exit
                </Button>
              </Stack>
              <Stack width={{ xs: "100%", md: "205px" }}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={methods.handleSubmit(handleProceed)}
                >
                  Proceed
                </Button>
              </Stack>
            </Stack>
          </Stack>
        }
      </Stack>
      <StyledModalV2
        open={confirmDialogOpen}
        onClose={() => {
          setConfirmDialogOpen(false);
          onClose();
        }}
        content={
          <Typography variant="h4" textAlign="center">
            {data?.vehicleOrderDetails?.status && data?.vehicleOrderDetails?.status == 'Draft' ? 'Vehicle order request updated successfully.' : 'Vehicle order request created successfully.'}
          </Typography>
        }
      />
      <StyledModalV2
        open={confirmSaveDialogOpen}
        onClose={() => setConfirmSaveDialogOpen(false)}
        content={
          <Typography variant="h4" textAlign="center">
            Any changes will not be saved. Are you sure you want to continue?
          </Typography>
        }
        actions={
          <StyledModalV2Actions
            onCancel={() => setConfirmSaveDialogOpen(false)}
            onConfirm={() => {
              setConfirmSaveDialogOpen(false);
              onClose();
            }}
          />
        }
      />
    </form>
  )
}

VehicleInformation.propTypes = {
  values: PropTypes.object.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  notEditable: PropTypes.bool,
}

export default VehicleInformation