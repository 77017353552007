import React, { useState, useRef, Fragment } from 'react'
import PropTypes from 'prop-types'

import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import VerticalAlignBottomTwoToneIcon from '@mui/icons-material/VerticalAlignBottomTwoTone';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import GetAppIcon from '@mui/icons-material/GetApp';

import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'

const Options = ({
  columns,
  filtering,
  hideFilterLabel,
  // func
  onClearFilters,
  onShowFilters,
  onRefresh,
  onDownload,
  onToggleShowColumns,
  ...props
}) => {

  const downloadMenuRef = useRef(null)
  const [open, setOpen] = useState(false)

  const toggleDownloadMenu = () => setOpen(!open)
  const onDownloadHandler = (selected, allColumns) => () => {
    onDownload(selected, allColumns)
    setOpen(false)
  }

  const noneSelected = Object.entries(columns)
    .filter(([key, value]) => value).length === 0
  
  const onRefreshHandler = () => {    
    onRefresh && onRefresh()
  }

  return (
    <Stack flexDirection="row" gap={1}>
      {onClearFilters && filtering && !hideFilterLabel && (
        <Button
          color='secondary'
          variant="contained"
          onClick={onClearFilters}
        >
          Clear Filters
        </Button>
      )}

      {onRefresh && (
        <Tooltip title="Refresh">
          <IconButton size="small" onClick={onRefreshHandler}>
            <CachedOutlinedIcon sx={{ color: 'black' }} />
          </IconButton>
        </Tooltip>
      )}
      
      {onDownload && (
        <Fragment>
          <Tooltip title="Download">
            <IconButton
              {...props}
              size="small"
              ref={downloadMenuRef}
              onClick={toggleDownloadMenu}
            >
              <VerticalAlignBottomTwoToneIcon sx={{ color: 'black' }} />
            </IconButton>
          </Tooltip>
          <Menu
            id="download-select"
            anchorEl={downloadMenuRef.current}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            onClose={toggleDownloadMenu}
            open={open}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            {/* Exception for FBT Summary download all option */}
            {window.location.pathname.includes('/fbt/summary') && (
              <MenuItem onClick={onDownloadHandler(true, true)}>
                <ListItemIcon>
                  <GetAppIcon sx={{ color: 'black' }} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="bodyLarge" pb={1}>
                    Download All
                  </Typography>
                </ListItemText>
              </MenuItem>
            )}
            {!window.location.pathname.includes('/fbt/summary') && (
              <MenuItem onClick={onDownloadHandler(false, false)}>
                <ListItemIcon>
                  <GetAppIcon sx={{ color: 'black' }} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="bodyLarge" pb={1}>
                    Download All
                  </Typography>
                </ListItemText>
              </MenuItem>
            )}
            {!noneSelected && <MenuItem onClick={onDownloadHandler(true, false)}>
              <ListItemIcon>
                <GetAppIcon sx={{ color: 'black' }} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="bodyLarge">
                  Download Filtered
                </Typography>
              </ListItemText>
            </MenuItem>}
          </Menu>
        </Fragment>
      )}

      {onShowFilters && (
        <Tooltip
          title="Filters">
          <IconButton
            size="small"
            onClick={onShowFilters}
          >
            <TuneRoundedIcon sx={{ color: 'black' }} />
          </IconButton>
        </Tooltip>
      )}

      {onToggleShowColumns && (
        <Tooltip
          title="Columns">
          <IconButton
            size="small"
            onClick={onToggleShowColumns}
          >
            <ViewColumnOutlinedIcon sx={{ color: 'black' }} />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  )
}

Options.propTypes = {
  columns: PropTypes.object.isRequired,
  filtering: PropTypes.bool,
  hideFilterLabel: PropTypes.bool,
  // func
  onClearFilters: PropTypes.func,
  onShowFilters: PropTypes.func,
  onRefresh: PropTypes.func,
  onDownload: PropTypes.func,
  onToggleShowColumns: PropTypes.func,
}

export default Options
