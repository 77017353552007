import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import MuiTextfield from '../mui-textfield'

const StaticAutocomplete = ({ id, name, control, label, value, disabled, options, onSelectedOptionChange, rules = {}, filterOptions }) => {

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      disabled={disabled}
      render={({ field, fieldState }) => (
        <Autocomplete
          {...field}
          options={options}
          getOptionLabel={(option) => option.text || ""}
          filterOptions={filterOptions}
          groupBy={(option) => option.group}
          renderGroup={(params) => (
            <div key={params.key}>
              <div style={{ fontWeight: 'bold', paddingLeft: '8px' }}>{params.group}</div>
              {params.children}
            </div>
          )}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(event, value) => {
            field.onChange(value);
            if (value) {
              onSelectedOptionChange(value);
            }
          }}
          value={value ? value : null}
          getOptionSelected={(option, value) => option.text === value.text}
          renderInput={(params) => (
            <MuiTextfield
              {...params}
              id={id}
              label={label}
              error={Boolean(fieldState.error)}
            />
          )}
        />
      )}
    />
  );
};

export default StaticAutocomplete;
