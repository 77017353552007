import React from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import { Controller } from 'react-hook-form';
import { OfflineLookupField } from 'frame/components'
import MuiTextfield from "frame/components/mui-textfield"
import Spinner from 'react-md-spinner'

export const Vehicle = ({
  values,
  vehicleDetails,
  onInputChange,
  methods,
  disabled,
  onSelectChange,
  onLookup,
  hasDetails,
  driverName,
  isLoading,
}) => {
  const theme = useTheme()
  const details = [
    {
      label: "Registration",
      value: vehicleDetails?.text,
    }, {
      label: "Colour",
      value: vehicleDetails?.colour,
    }
  ]

  return (
    <Stack gap={2} width="100%">
      <Stack>
        <Typography variant="h5" fontWeight={600}>
          Vehicle Details
        </Typography>
      </Stack>

      <>
      {isLoading ? (
        <Stack
          borderRadius='5px'
          border='1px solid'
          borderColor={theme.palette.accent.sky}
          p={3}
          gap={2}
          justify="center"
          alignItems="center"
        >
          <Spinner
            singleColor={theme.palette.dark}
            size={25}
          />
        </Stack>
      ): (
        <>
          {hasDetails ? (
            <Stack
              borderRadius='5px'
              border='1px solid'
              borderColor={theme.palette.accent.sky}
              p={3}
              gap={2}
            >
              <Stack flexDirection="row" gap={4}>
                <Stack>
                  <Typography variant="bodyLarge" color={theme.palette.accent.sky}>
                    Vehicle
                  </Typography>
                  <Typography variant="bodyLarge">
                    {vehicleDetails?.vehicle}
                  </Typography>
                </Stack>
                <Stack flexDirection="row" gap={6}>
                  {details?.map((detail, index) => (
                    <Stack
                      key={`driver-edit-details-${index}`}
                    >
                      <Typography variant="bodyLarge" color={theme.palette.accent.sky}>
                        {detail?.label}
                      </Typography>
                      <Typography variant="bodyLarge">
                        {detail?.value}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
              
              <Stack gap={2}>
                <Stack gap={2} flexDirection="row">
                  {/* cost centre */}
                  <Stack gap={1} flex={1}>
                    <Controller
                      name="costCentre"
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <OfflineLookupField
                          {...field}
                          fullWidth
                          showAllResult
                          allowFreeText
                          id="costCentre"
                          label='Cost Centre'
                          disabled={disabled}
                          customFleetId={values?.thirdPartyNumber}
                          lookupPath="drivercostcentre"
                          value={values?.costCentre || ''}
                          variant="outlined"
                          onChange={({ value }) => {
                            onSelectChange('costCentre', value)
                          }}
                          defaultOptions={values?.costCentre ? [{ text: values?.costCentre, value: values?.costCentre }] : []}
                          onLookup={onLookup}
                          resourceModel="drivers"
                        />
                      )}
                    />
                  </Stack>
                  {/* location */}
                  <Stack gap={1} flex={1}>
                    <Controller
                      name="location"
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <OfflineLookupField
                          {...field}
                          showAllResult
                          id="location"
                          disabled={disabled}
                          fullWidth
                          label="Location"
                          lookupPath="location"
                          value={values?.location || 'Unknown'}
                          variant="outlined"
                          onChange={({ value }) => {
                            onSelectChange('location', value)
                          }}
                          defaultOptions={values?.location ? [{ text: values?.location, value: values?.location }] : []}
                          onLookup={onLookup}
                          resourceModel="drivers"
                        />
                      )}
                    />
                  </Stack>
                  {/* fleet controller */}
                  <Stack gap={1} flex={1}>
                    <Controller
                      name="controller"
                      control={methods.control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <OfflineLookupField
                          {...field}
                          showAllResult
                          id="controller"
                          disabled={disabled}
                          fullWidth
                          label="Fleet Controller"
                          lookupPath="driverfleetcontroller"
                          customFleetId={values?.thirdPartyNumber}
                          value={values?.controller || 'Unknown'}
                          variant="outlined"
                          onChange={({ value }) => {
                            onSelectChange('controller', value)
                          }}
                          defaultOptions={values?.controller ? [{ text: values?.controller, value: values?.controller }] : []}
                          onLookup={onLookup}
                          resourceModel="drivers"
                        />
                      )}
                    />
                  </Stack>
                </Stack>
                {/* vehicle notes */}
                <Stack gap={1} flex={1}>
                  <Controller
                    name="vehicleNotes"
                    control={methods.control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <MuiTextfield
                        {...field}
                        multiline
                        fullWidth
                        rows={3}
                        size="small"
                        id="vehicleNotes"
                        label='Vehicle Notes'
                        disabled={disabled}
                        value={values?.vehicleNotes || ''}
                        inputProps={{ maxLength: 50 }}
                        onChange={onInputChange}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>
          ): (
            <Stack
              borderRadius='5px'
              border='1px solid'
              borderColor={theme.palette.accent.sky}
              p={3}
              gap={2}
            >
              <Typography variant="h6" fontWeight={400}>
                No vehicle currently assigned to {driverName}.
              </Typography>
            </Stack>
          )}
        </>
      )}
      </>
    </Stack>
  )
}