import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { colors } from '@material-ui/core'
import { getDateColor, /*getKmUseRateColor,*/ getWoFStatusColor, obfuscateAgreementNumber } from 'listable'
import { createRowSelector, useRouter } from 'utils'
import { Label, FleetIcon } from 'frame/components'
import RowActions from './row-options'
import { types } from '../../module'
import { useEnabledFeatureToggleList } from 'features/settings'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { themeV5 } from 'theme/v5'

import TableCellView from 'frame/components/table-cell/table-cell'

const Row = ({ account, item, columns, onView }) => {
  const { history, location } = useRouter()
  //const [viewAccessories, setViewAccessories] = useState(false)
  const onSafeRowClick = createRowSelector(onView)
  //const isPremium = account === 'premium'

  // const onViewAccessories = () =>
  //   isLengthyAccessories && setViewAccessories(true)

  // const isLengthyAccessories = item.accessories.length > 30
  // const accessories = item.accessories && item.accessories.split(', ')

  const featureToggleList = useEnabledFeatureToggleList()
  const tfp1599FeatureToggle = featureToggleList.includes('TFP1599')
  const documentsReportFeatureToggle = featureToggleList.includes('DocumentsViewing')
  const tfp1929FeatureToggle = featureToggleList.includes('TFP1929')
  const tfp1845FeatureToggle = featureToggleList.includes('TFP1845')
  const tfp1944FeatureToggle = featureToggleList.includes('TFP1944')
  const tfp2005FeatureToggle = featureToggleList.includes('TFP2005')

  const onViewDocumentsReport = () => {
    history.push(`/reports/documents?registration=${item.registration}`, { label: 'Fleet',  path: location.pathname })
  }

  return (
    <TableRow
      hover
      // onClick={columns.accessories && viewAccessories ? false : onSafeRowClick}>
      sx={{
        boxSizing: 'border-box',
        cursor: 'pointer',
        verticalAlign: 'middle',
        height: '30px',
        borderBottom: '5px solid white',
      }}
      onClick={onSafeRowClick}
    >
      <TableCell sx={{ bgcolor: 'white', width: '10px', p: 0 }} />
      {columns.accountName && (
        <TableCellView isFirst>          
          <FleetIcon fleetName={item.accountName} />
        </TableCellView>
      )}
      {columns.agreementNo && (
        <TableCellView>
          {obfuscateAgreementNumber(item.agreementNo || '-', true)}
        </TableCellView>
      )}
      {columns.agreementType && (
        <TableCellView>
          {item.agreementType || '-'}
        </TableCellView>
      )}
      {columns.registration && (
        <TableCellView>
          {item.registration || '-'}
        </TableCellView>
      )}
      {columns.assetBranch && (
        <TableCellView>
          {item.assetBranch || '-'}
        </TableCellView>
      )}
      {columns.assetFunction && (
        <TableCellView>
          {item.assetFunction || '-'}
        </TableCellView>
      )}              
      {columns.driverNote && tfp1944FeatureToggle && (
        <TableCellView>
          {item.driverNote || '-'}
        </TableCellView>
      )}      
      {columns.driver && (
        <TableCellView>
          {item.driver || '-'}
        </TableCellView>
      )}
      {columns.year && (
        <TableCellView>
          {item.year || '-'}
        </TableCellView>
      )}
      {columns.make && (
        <TableCellView>
          {item.make || '-'}
        </TableCellView>
      )}
      {columns.model && (
        <TableCellView>
          {item.model || '-'}
        </TableCellView>
      )}
      {columns.variant && (
        <TableCellView>
          {item.variant || '-'}
        </TableCellView>
      )}
      {columns.colour && (
        <TableCellView>
          {item.colour || '-'}
        </TableCellView>
      )}
      {columns.fuelType && (
        <TableCellView>
          {item.fuelType || '-'}
        </TableCellView>
      )}
      {columns.noOfTyres && (
        <TableCellView>
          {item.noOfTyres || '-'}
        </TableCellView>
      )}
      {columns.tyresRemaining && (
        <TableCellView>
          {tfp1599FeatureToggle ? item.tyresRemaining : '-' || '-'}
        </TableCellView>
      )}      
      {columns.costCentre && (
        <TableCellView>
          {item.costCentre || '-'}
        </TableCellView>
      )}
      {columns.startDate && (
        <TableCellView>
          {item.startDate ? moment(item.startDate).format('DD/MM/YY') : '-'}
        </TableCellView>
      )}
      {columns.maturityDate && (
        <TableCellView>
          {item.maturityDate ? (
            <Label
              color={getDateColor(item.maturityDate)}
              variant="outlined">
              {moment(item.maturityDate).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.originalMaturityDate && tfp2005FeatureToggle && (
        <TableCellView>
          {item.originalMaturityDate ? moment(item.originalMaturityDate).format('DD/MM/YY') : '-'}
        </TableCellView>
      )}
      {columns.monthsOnVerbal && (
        <TableCellView>
          {item.monthsOnVerbal || '-'}
        </TableCellView>
      )}
      {columns.term && (
        <TableCellView>
          {item.term || '-'}
        </TableCellView>
      )}
      {columns.variationsCount && (
        <TableCellView>
          {item.variationsCount > 0 ? (
            <Label
              color={colors.green[600]}
              variant="contained">
              {item.variationsCount}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.contractKm && (
        <TableCellView>
          {item.contractKm ? parseInt(item.contractKm, 10).toLocaleString('en-NZ') : '-'}
        </TableCellView>
      )}
      {columns.kmUseRate && (
        <TableCellView>
          {item.kmUseRate ? parseInt(item.kmUseRate, 10).toLocaleString('en-NZ') : '-'}
        </TableCellView>
      )}
      {columns.contractEndKm && tfp1845FeatureToggle && (
        <TableCellView>
          {item.contractEndKm ? parseInt(item.contractEndKm, 10).toLocaleString('en-NZ') : '-'}
        </TableCellView>
      )}      
      {columns.location && (
        <TableCellView>
          {item.location || '-'}
        </TableCellView>
      )}
      {columns.vehicleNotes && tfp1929FeatureToggle && (
        <TableCellView>
          {item.vehicleNotes || '-'}
        </TableCellView>
      )}
      {columns.controller && (
        <TableCellView>
          {item.controller ? (
            item.controller
          ) : '-'}
        </TableCellView>
      )}    
      {columns.lastOdoReading && (
        <TableCellView>
          {item.lastOdoReading ? parseInt(item.lastOdoReading, 10).toLocaleString('en-NZ') : '-'}
        </TableCellView>
      )}        
      {columns.lastKmReading && (
        <TableCellView>
          {item.lastKmReading ? moment(item.lastKmReading).format('DD/MM/YY') : '-'}
        </TableCellView>
      )}      
      {columns.budgetedKmYear && (
        <TableCellView>
          {item.budgetedKmYear ? parseInt(item.budgetedKmYear, 10).toLocaleString('en-NZ') : '-'}
        </TableCellView>
      )}
      {columns.wofExpiry && (
        <TableCellView>
          {item.wofExpiry ? (
            <Label
              color={getWoFStatusColor(item.wofStatus)}
              variant="outlined">
              {moment(item.wofExpiry).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.wofStatus && (
        <TableCellView>
          {item.wofExpiry ? (
            <Label
              color={getWoFStatusColor(item.wofStatus)}
              variant="outlined">
              {item.wofStatus}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.registrationExpiry && (
        <TableCellView>
          {item.registrationExpiry ? (
            <Label
              color={getWoFStatusColor(item.registrationStatus)}
              variant="outlined">
              {moment(item.registrationExpiry).format('DD/MM/YY')}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.registrationStatus && (
        <TableCellView>
          {item.registrationExpiry ? (
            <Label
              color={getWoFStatusColor(item.registrationStatus)}
              variant="outlined">
              {item.registrationStatus}
            </Label>
          ) : '-'}
        </TableCellView>
      )}
      {columns.regularInstalment && (
        <TableCellView>
          {item.regularInstalment ? item.regularInstalment.toLocaleString('en-NZ', {
            style: 'currency',
            currency: 'NZD',
          }) : '-'}
        </TableCellView>
      )}
      {columns.documentsCount && documentsReportFeatureToggle && (
        <TableCellView>
          {( item.documentsCount > 0 ? (            
            <Tooltip title="View documents">
              <IconButton className="ignore" onClick={onViewDocumentsReport}>
                <InsertDriveFileOutlinedIcon sx={{ color: themeV5.palette.primary.main }} />
              </IconButton>
            </Tooltip>
          ) : '-')}
        </TableCellView>
      )}

      <TableCellView sx={{ position: 'relative'}} isLast>
        <RowActions sx={{
            flexDirection: 'row',
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            alignItems: 'center',
          }}
        className="ignore" item={item} onView={onView} />
      </TableCellView>
      {/* {Object.values(columns).some(value => value === true) &&            
        (<TableCellView isLast></TableCellView>)
      } */}
    </TableRow>
  )
}

Row.propTypes = {
  account: PropTypes.string,
  item: types.ModelType.isRequired,
  columns: types.ColumnsType.isRequired,
  onView: PropTypes.func.isRequired,
}

export default Row
